import fetch from '@/utils/fetch'

export const List = '/order/risk/list'

/** 导出 */
export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}

/** 批量删除 */
export function batchDelete (id) {
  return fetch({
    url: '/order/risk/batchdelete',
    method: 'post',
    data: {
      id
    }
  })
}

/** 删除单个 */
export function deleteItem (id) {
  return fetch({
    url: `/order/risk/${id}`,
    method: 'delete'
  })
}

/** 关闭订单 */
export function closeOrder (id) {
  return fetch({
    url: '/order/cancel/risk',
    method: 'post',
    data: {
      id
    }
  })
}

/** 失败重试 */
export function retryCancelOrder (id) {
  return fetch({
    url: '/order/retrycancel/risk',
    method: 'post',
    data: {
      id
    }
  })
}
