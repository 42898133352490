<template>
  <div class="section section-white" v-loading = "page_loading">
    <el-form ref="form" :model="form" label-position="right" label-width="150px">
      <div class="section-body">
        <template>
          <el-card header="基础设置">
            <el-form-item label="订单频率">
              在 <el-input type="number" required min="1" v-model="form.limit_second" placeholder="" style="width: 100px;" /> 秒内，每个店铺每个商品仅能创建一笔订单。
            </el-form-item>
          </el-card>

          <el-card header="商品设置">
            <SkuSelector
              @change="getItems"
              :data="relItems"
            ></SkuSelector>
          </el-card>
        </template>
      </div>
      <div class="section-footer with-border content-center">
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
  import SkuSelector from '@/components/function/skuSelector'
  import {setCreateOrderLimitSetting, getCreateOrderLimitSetting} from '@/api/trade'
  export default {
    components: {
      SkuSelector,
    },
    data () {
      return {
        form: {
          limit_second: 0,
          origin_bns: [],
          item_ids: [],
        },
        relItems: [],
        page_loading: true,
      }
    },
    methods: {
      getItems (data) {
        let originBns = []
        let itemIds = []
        data.forEach(item => {
          originBns.push(item.origin_bn)
          itemIds.push(item.item_id)
        })
        this.form.origin_bns = originBns
        this.form.item_ids = itemIds // item_id用于数据回显
      },

      save () {
        this.page_loading = true
        let params = this.form
        setCreateOrderLimitSetting (params) .then(response => {
          if (response.data.data.success) {
            this.$message.success('修改成功')
          } else {
            this.$message.error('修改失败')
          }
          this.page_loading = false
        }) . catch(error => {
          this.$message.error('修改失败')
          this.page_loading = false
        })
      }
    },
    mounted () {
      getCreateOrderLimitSetting () . then(response => {
        if (response.data.data) {
          let res_data = response.data.data
          this.form.limit_second = res_data.limit_second || 0
          this.form.origin_bns = res_data.origin_bns || []
          this.form.item_ids = res_data.item_ids || []
          this.relItems = res_data.itemTreeLists || []
        }
        this.page_loading = false
      }) . catch (error => {
        this.$message.error('获取失败')
        this.page_loading = false
      })
    }
  }
</script>
<style scoped lang="scss">
</style>
