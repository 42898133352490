<template>
  <div>
    <el-form label-width="100px">
      <el-form-item label="选择日期范围">
        <el-row :gutter="20">
          <el-col :span="9">
            <el-date-picker v-model="vdate" value-format="yyyy-MM-dd" type="daterange" unlink-panels  align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%"  ></el-date-picker>
          </el-col>
          <el-col :span="5">
            <el-select v-model="params.type" placeholder="选择数据类型" style="width: 100%">
              <el-option label="所有数据" key="1" value="1">所有数据</el-option>
              <el-option label="平台数据" key="2" value="2">平台数据</el-option>
              <el-option label="非平台数据" key="3" value="3">非平台数据</el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="订单号" v-model="params.order_id" />
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="getList(true)">搜索</el-button>
            <el-button type="primary" @click="exportData()">导出</el-button>
            <el-popover placement="top-start" width="200" trigger="hover" content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <div>
      <el-card>
        <div class="account-number">
          <span
            ><h4 class="account-hd">总计收入</h4>
            ￥ {{totle.income}}</span
          >
          <span
            ><h4 class="account-hd">总计支出</h4>
            ￥ {{totle.disburse}}</span
          >
          <span
            ><h4 class="account-hd">总计提现</h4>
            ￥ {{totle.withdrawal}}</span
          >
          <span
            ><h4 class="account-hd">余额</h4>
            ￥ {{totle.balance}}</span
          >
          <span
            ><h4 class="account-hd">可提现余额</h4>
            ￥ {{totle.withdrawal_balance}}</span
          >
          <span
            ><h4 class="account-hd">未结算资金</h4>
            ￥ {{totle.unsettled_funds}}</span
          >
        </div>
      </el-card>
      <el-table :data="allListData" stripe border style="width: 100%" v-loading="loading">
        <el-table-column prop="trade_time" label="日期" fixed></el-table-column>
        <el-table-column prop="order_id" label="订单号"></el-table-column>
        <el-table-column prop="fin_type" label="类型"></el-table-column>

        <el-table-column prop="income" label="金额">
          <template slot-scope="scope">
            <span v-if="scope.row.income!=0" style="color: #70B603;">
              +￥{{ scope.row.income  }}
            </span>
             <span v-else style="color: red;">
              -￥{{ scope.row.outcome  }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="total_count > params.page_size" class="content-padded content-center">
        <el-pagination background layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page.sync="params.page" :page-sizes="[10, 20, 50]" :total="total_count" :page-size="params.page_size"> </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { getPlatformAccount,exportAccount } from '@/api/fenzhang'
export default {
  data() {
    return {
      vdate:'',
      loading: true,
      params: {
        page: 1,
        page_size: 20,
        order_id: '',
        type: '1',
        start_date: '',
        end_date: '',
       
      },
      total_count: 0,
      loading:false,
      allListData: [],
      totle: {
        income: 0,
        disburse: 0,
        withdrawal: 0,
        refund: 0,
        balance: 0,
        withdrawal_balance: 0,
        unsettled_funds: 0,
      },
    }
  },
  methods: {
   
    getList() {
      this.params.start_date = this.vdate[0]
      this.params.end_date = this.vdate[1]
    this.loading=true
      getPlatformAccount(this.params)
        .then((res) => {
          
          this.allListData = res.data.data.list.data
          this.total_count=res.data.data.list.total_count
          this.totle=res.data.data.totle
          this.loading = false
        })
        
    },
    handleSizeChange(page_size) {
      this.params.page = 1
      this.params.page_size = page_size
      this.getList()
    },
    handleCurrentChange(page) {
      this.params.page = page
      this.getList()
    },
     exportData(){
      let obj={
        start_date:this.params.start_date,
        end_date:this.params.end_date,
        order_id:this.params.order_id,
        
      }
      exportAccount(obj).then(res=>{
        this.$message({
                type: 'success',
                message: '已加入执行队列，请在设置-导出列表中下载'
              });
      })
    }
  },
  mounted() {
   var start = new Date()
    var end = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
    this.vdate = [start, end]

    this.getList()
  },
}
</script>
<style lang="scss" scoped>
.account-number {
  font-size: 16px;

  span {
    display: inline-block;
    margin-right: 25px;
    color: red;
    .account-hd {
      font-size: 18px;
      display: inline-block;
    }
  }
}
</style>