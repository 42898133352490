<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="5" style="margin-top: 10px">
        <el-radio-group v-model="params.api_type" size="small" @change="searchDate">
          <el-radio label="request">request</el-radio>
          <el-radio label="response">response</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="7" style="margin-top: 10px">
        <el-radio-group v-model="params.status" size="small" @change="searchDate">
          <el-radio label="running">执行中</el-radio>
          <el-radio label="success">成功</el-radio>
          <el-radio label="fail">失败</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="5" style="margin-top: 10px">
        <el-radio-group v-model="params.method" size="small" @change="searchDate">
          <el-radio label="request">请求</el-radio>
          <el-radio label="response">响应</el-radio>
          <el-radio label="patch">重推</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="7">
        <el-input placeholder="请输入库名【参考下面说明】" v-model="params.mongo_db" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="7">
        <el-input placeholder="请输入接口名称" v-model="params.worker" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>

      <el-col :span="7">
        <el-input placeholder="请输入msgId 例：订单号" v-model="params.msg_id" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>

      <el-col :span="7">
        <el-input placeholder="请输入内容 模糊查询 比较慢" v-model="params.content" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>
      <el-col :span="10">
        <el-date-picker
          v-model="params.updated"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="最后更新开始日期"
          end-placeholder="最后更新结束日期"
          align="right" @change="searchDate" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-tag>库名说明： 库存相关：omsApi_RecMongo_sku_'年月'， 其他 omsApi_RecMongo_'年月'， 旧数据omsApi_RecMongo</el-tag>
    <el-table :data="dataList" v-loading="loading" :height="wheight-150">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-button type="primary" size="small"
                     v-clipboard:copy="copyUrl(props.row.params)"
                     v-clipboard:success="onCopy"
          >
            复制参数
          </el-button>
          <el-button type="primary" size="small"
                     v-clipboard:copy="copyUrl(props.row.result)"
                     v-clipboard:success="onCopy"
          >
            复制返回值
          </el-button>
          <el-button type="primary" size="small"
                     v-clipboard:copy="copyUrl(props.row.retry_result)"
                     v-clipboard:success="onCopy"
          >
            复制重试结果
          </el-button>
          <el-collapse>
            <el-collapse-item title="展开查看参数详情" name="1" >
              {{props.row.params}}
            </el-collapse-item>
            <el-collapse-item title="展开查看返回值详情" name="2">
              {{props.row.result}}
            </el-collapse-item>
<!--            <el-collapse-item title="重试结果" name="3">-->
<!--              {{props.row.retry_result}}-->
<!--            </el-collapse-item>-->
          </el-collapse>
        </template>
      </el-table-column>
      <el-table-column width="80" prop="api_type" label="类型"></el-table-column>
      <el-table-column width="80" prop="method" label="接口方向"></el-table-column>
      <el-table-column width="80" prop="status" label="状态"></el-table-column>
      <el-table-column prop="runtime" width="80" label="时间"></el-table-column>
      <el-table-column prop="worker" label="接口名称"></el-table-column>
      <el-table-column prop="msg_id" label="msg_id"></el-table-column>
      <!--  -->
      <el-table-column prop="created_date" label="创建时间"></el-table-column>
<!--      <el-table-column label="操作">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button v-if="scope.row.api_type != 'receive' && scope.row.method != 'GET'"  size="mini" @click="retryAction(scope.row.id)">重试</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div class="content-padded content-center">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10,20,50]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import cookie from "js-cookie"
  import { mapGetters } from 'vuex'
  import { OmsQueueLogList } from '@/api/trade'
  // import { OmsQueueLogList,resultRetry } from '@/api/trade'
  export default {
    data () {
      return {
        loading: false,
        total_count: 0,
        dataList: [],
        params: {
          page: 1,
          pageSize: 20,
          api_type: '',
          method: '',
          status: '',
          content: '',
          worker: '',
          msg_id: '',
          mongo_db: '',
          updated: [],
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      onCopy(e) {
        this.$message({message: '复制成功', type: 'success'})
      },
      copyUrl(data) {
        return JSON.stringify(data)
      },
      // retryAction(id) {
      //   console.log(id)
      //   resultRetry(id).then(response => {
      //     this.$message({
      //       type: 'success',
      //       message: '重试成功，请查看重试结果'
      //     })
      //     this.getDataList()
      //   })
      // },
      getDataList () {
        this.loading = true
        OmsQueueLogList(this.params).then(response => {
          this.dataList = response.data.data.list
          this.total_count = response.data.data.total_count
          this.loading = false
        })
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getDataList()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getDataList()
      },
      searchDate() {
        this.params.page = 1
        this.getDataList()
      },
    },
    mounted () {
      this.initWebSocket();
      // this.getDataList()
    }
  }
</script>

