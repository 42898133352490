<template>
  <div>

    <el-row :gutter="20">
      <el-col :span="5">
        <el-radio-group v-model="params.type" size="small" @change="searchDate">
          <el-radio label="2">增量</el-radio>
          <el-radio label="1">全量</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="5">
        <el-radio-group v-model="params.direction" size="small" @change="searchDate">
          <el-radio label="1">入库</el-radio>
          <el-radio label="2">出库</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="5">
        <el-radio-group v-model="params.warehouseType" size="small" @change="searchDate">
          <el-radio label="WAREHOUSE">仓库</el-radio>
          <el-radio label="STORE">门店</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="请输入库名【参考下面说明】" v-model="params.mongo_db" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="4">
        <el-input placeholder="请输入商品编号" v-model="params.extentionCode" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>

      <el-col :span="4">
        <el-input placeholder="请输入uniqueKey(订单号)" v-model="params.uniqueKey" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>

      <el-col :span="4">
        <el-input placeholder="请输入店铺编码" v-model="params.storeCode" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="searchDate"></el-button>
        </el-input>
      </el-col>
      <el-col :span="10">
        <el-date-picker
          v-model="params.updated"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="最后更新开始日期"
          end-placeholder="最后更新结束日期"
          align="right" @change="searchDate" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-tag>库名说明：库存相关：bzOmsItemsInventory_RecMongo_'年月'， 冻结：order_freeze_RecMongo_202207    旧数据bzOmsItemsInventory_RecMongo</el-tag>
    <el-table :data="dataList" v-loading="loading" :height="wheight-150">
      <el-table-column width="180" prop="extentionCode" label="商品编码"></el-table-column>
      <!--      <el-table-column   width="120" prop="skuId" label="skuId"></el-table-column>-->
      <el-table-column width="80" prop="type" label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1"> 全量</span>
          <span v-else>增量</span>
        </template>
      </el-table-column>
      <el-table-column width="130" prop="direction" label="方向">
        <template slot-scope="scope">
          <span v-if="scope.row.direction === 1"> 入库</span>
          <span v-if="scope.row.direction === 2"> 出库</span>
          <span v-if="scope.row.direction === '解冻'"> 解冻</span>
          <span v-if="scope.row.direction === '冻结'"> 冻结</span>


<!-- order_freeze_RecMongo_202207 -->
        </template>
      </el-table-column>
      <el-table-column width="80" prop="qty" label="数量">
        <template slot-scope="scope">
          <span v-if="scope.row.direction === 1"> +{{scope.row.qty}}</span>
          <span v-if="scope.row.direction === 2"> -{{scope.row.qty}}</span>
          <span v-if="scope.row.direction === '解冻'"> {{scope.row.qty}}</span>
          <span v-if="scope.row.direction === '冻结'"> +{{scope.row.qty}}</span>
        </template>
      </el-table-column>
      <el-table-column width="180"  prop="storeCode" label="店铺编码(shopcode)">
        <template slot-scope="scope">
          {{scope.row.storeCode}}
          <el-tag  type="success" v-if="scope.row.warehouseType === 'WAREHOUSE'">仓</el-tag>
          <el-tag v-else type="warning">店</el-tag>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="numberId" label="spuId"></el-table-column>-->
      <el-table-column prop="uniqueKey" label="uniqueKey" width="200"></el-table-column>
      <el-table-column prop="record_type" width="100" label="记录类型"></el-table-column>
      <el-table-column prop="des" width="150" label="说明"></el-table-column>
      <el-table-column prop="opTime" width="180" label="操作时间"></el-table-column>
    </el-table>
    <div class="content-padded content-center">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10,20,50]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import {OmsSkuLogList,resultRetry } from '@/api/trade'
  import { OmsSkuLogList } from '@/api/trade'
  export default {
    data () {
      return {
        loading: false,
        total_count: 0,
        dataList: [],
        params: {
          page: 1,
          pageSize: 20,
          type: '',
          direction: '',
          uniqueKey: '',
          extentionCode: '',
          storeCode: '',
          mongo_db: '',
          updated: [],
          warehouseType:''
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      onCopy(e) {
        this.$message({message: '复制成功', type: 'success'})
      },
      copyUrl(data) {
        return JSON.stringify(data)
      },
      // retryAction(id) {
      //   console.log(id)
      //   resultRetry(id).then(response => {
      //     this.$message({
      //       type: 'success',
      //       message: '重试成功，请查看重试结果'
      //     })
      //     this.getDataList()
      //   })
      // },
      getDataList () {
        this.loading = true
        OmsSkuLogList(this.params).then(response => {
          this.dataList = response.data.data.list
          this.total_count = response.data.data.total_count
          this.loading = false
        })
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getDataList()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getDataList()
      },
      searchDate() {
        this.params.page = 1
        this.getDataList()
      }
    },
    mounted () {
      this.getDataList()
    }
  }
</script>

