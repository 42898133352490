import { render, staticRenderFns } from "./statistics-material.vue?vue&type=template&id=2b695363&scoped=true"
import script from "./statistics-material.vue?vue&type=script&lang=js"
export * from "./statistics-material.vue?vue&type=script&lang=js"
import style0 from "./statistics-material.vue?vue&type=style&index=0&id=2b695363&prod&scoped=true&leng=scss&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b695363",
  null
  
)

export default component.exports