<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-input class="input-m" placeholder="订单号" v-model="identifier"></el-input>
          <el-input class="input-m" placeholder="品牌" v-model="brand"></el-input>
          <el-input class="input-m" placeholder="商品品类" v-model="main_category"></el-input>
        </el-col>
        <el-col>
            下单日期 <el-date-picker class="input-m" v-model="create_time" type="daterange" value-format="yyyy/MM/dd" placeholder="选择日期范围" @change="dateChange"></el-date-picker>
          </el-col>
          <el-col>
            发货日期 <el-date-picker class="input-m" v-model="delivery_time" type="daterange" value-format="yyyy/MM/dd" placeholder="选择日期范围" @change="dateDeliveryChange"></el-date-picker>
            <el-button type="primary" @click="exportData">导出</el-button>
            <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
      </el-row>
      
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { exportSalesReport } from '../../../../api/trade'
  export default {
    data () {
      return {
        currentShop: '',
        loading: false,
        create_time: '',
        delivery_time: '',
        params: {
          page: 1,
          pageSize: 20,
          aftersales_status: 2,
        },
        time_start_begin: '',
        time_start_end: '',
        delivery_time_start_begin: '',
        delivery_time_start_end: '',
        total_count: 0,
        list: [],
        identifier: '',
        brand: '',
        main_category: ''
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      onCopy () {
        this.$notify.success({
          message: '复制成功',
          showClose: true
        });
      },
      dateChange (val) {
        if (val && val.length > 0) {
          this.time_start_begin = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.time_start_end = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.time_start_begin = ''
          this.time_start_end = ''
        }
      },
      dateDeliveryChange (val) {
        if (val && val.length > 0) {
          this.delivery_time_start_begin = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.delivery_time_start_end = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.delivery_time_start_begin = ''
          this.delivery_time_start_end = ''
        }
      },
      getParams () {
        this.params.time_start_begin = this.time_start_begin
        this.params.time_start_end = this.time_start_end
        this.params.delivery_time_start_begin = this.delivery_time_start_begin
        this.params.delivery_time_start_end = this.delivery_time_start_end
        this.params.order_id = this.identifier
        this.params.brand = this.brand
        this.params.main_category = this.main_category
      },
      dateStrToTimeStamp (str) {
        return Date.parse(new Date(str))/1000
      },
      querySearch(queryString, cb) {
        var restaurants = this.source_list;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        }
      },
      exportData () {
          this.getParams()
          this.params.page = 1
          exportSalesReport(this.params).then(response => {
              if (response.data.data.status) {
                  this.$message({
                      type: 'success',
                      message: '已加入执行队列，请在设置-导出列表中下载'
                  })
                  return
              } else if (response.data.data.url) {
                  this.downloadUrl = response.data.data.url
                  this.downloadName = response.data.data.filename
                  this.downloadView = true
              } else {
                  this.$message({
                      type: 'error',
                      message: '没有相关数据可导出'
                  })
              }
          })
      }
    }
  }
</script>
