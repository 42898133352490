<template>
  <div class="section section-white">
    <el-form ref="form" :model="form" label-position="left" label-width="150px">
      <div class="section-body">
        <template>
          <el-form-item label="预约失效取消:">
              预约店铺自提订单，将在 <el-input type="number" required min="1" v-model="form.appointment_cancel_time" placeholder="" style="width: 100px;" />天之后取消
          </el-form-item>
          <el-form-item label="预约失效通知:">
              预约订单到店提醒，将在 <el-input type="number" required min="1" v-model="form.appointment_remind_time" placeholder="" style="width: 100px;" />小时失效之前发出订阅消息提醒
          </el-form-item>


          <el-form-item label="订单自动取消:">
              未付款订单，将在 <el-input type="number" required min="1" v-model="form.order_cancel_time" placeholder="" style="width: 100px;" />(分钟)之后取消
          </el-form-item>
          <el-form-item label="订单自动完成:">
              已签收订单，将在 <el-input type="number" required min="0" v-model="form.order_finish_time" placeholder="" style="width: 100px;" /> (天)后自动完成
          </el-form-item>
          <!--
          <el-form-item label="订单售后时效:">
              已确认收货订单，将在 <el-input type="number" required min="0" v-model="form.latest_aftersale_time" placeholder="" style="width: 100px;" /> (天)后不可申请售后
          </el-form-item>
           -->
          <el-form-item label="AdaPay账户提现期:">
            已签收订单，将在 <el-input type="number" required min="0" v-model="form.auto_confirm_time" placeholder="" style="width: 100px;" /> (天)后可供提现
          </el-form-item>

          <el-form-item label="未开票提醒:">
            订单完成 <el-input type="number" required min="0" v-model="form.order_invoice_notice_time" placeholder="" style="width: 100px;" /> 小时后未开票提醒
          </el-form-item>

<!--          <el-form-item label="售后自动驳回时效:">-->
<!--            退货退款同意后未退回商品，将在 <el-input type="number" required min="0" v-model="form.auto_refuse_time" placeholder="" style="width: 100px;" /> (天)后驳回售后-->
<!--          </el-form-item>-->
        </template>
      </div>
      <div class="section-footer with-border content-center">
        <el-button type="primary" @click="save">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
  import { getOrderSetting, setOrderSetting } from '@/api/trade'
  export default {
    data () {
      return {
          form: {
            appointment_cancel_time: 1,
            appointment_remind_time: 24,
            order_cancel_time: 15,
            order_finish_time: 7,
            latest_aftersale_time: 0,
            auto_confirm_time: 0,
            auto_refuse_time: 0,
            order_invoice_notice_time: 0,
          },
      }
    },
    methods: {
      save() {
        console.log(this.form)
        setOrderSetting(this.form).then(res => {
            this.$message({message: '保存成功', type: 'success'})
        })
      }
    },
    mounted () {
      getOrderSetting().then(res => {
        let setting = res.data.data
        if (setting.appointment_cancel_time) {
          this.form.appointment_cancel_time = setting.appointment_cancel_time
        }
        if (setting.appointment_remind_time) {
          this.form.appointment_remind_time = setting.appointment_remind_time
        }
        if (setting.order_cancel_time) {
          this.form.order_cancel_time = setting.order_cancel_time
        }
        if (setting.order_finish_time) {
          this.form.order_finish_time = setting.order_finish_time
        }
        if (setting.latest_aftersale_time) {
          this.form.latest_aftersale_time = setting.latest_aftersale_time
        }
        if (setting.auto_confirm_time) {
          this.form.auto_confirm_time = setting.auto_confirm_time
        }
        if (setting.auto_refuse_time) {
            this.form.auto_refuse_time = setting.auto_refuse_time
        }
        if (setting.order_invoice_notice_time) {
          this.form.order_invoice_notice_time = setting.order_invoice_notice_time
        }
      })
    }
  }
</script>
<style scoped lang="scss">
</style>
