<template>
  <div class="upload-btns">
    <el-upload class="upload-demo" action=" " :show-file-list="false" :auto-upLoad="true" :http-request='uploadHandleChange'>
      <el-button class="btnupload" type="primary" plain size="medium">{{ btnText }}</el-button>
    </el-upload>
    <el-button @click="uploadHandleTemplate()" plain type="primary">下载模版</el-button>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { setChannelParamsUpload, getRelDistributorUpload } from '@/api/material'
import { exportUploadTemplate } from "@/api/common";
import store from '@/store'

export default {
  props: {
    tempId: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      defualt: ''
    },
    category: {
      type: String,
      defualt: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
  },
  components: {},
  data() {
    return {
      actionUrl: process.env.VUE_APP_BASE_API,
      paramsData: {
        temp_id: this.tempId,
      },
      storeet: store,
    }
  },
  methods: {
    uploadHandleChange(request) {
      if(this.category == 'utm'){
        this.getChannelParamsUpload(request)
      }else if(this.category == 'shop'){
        this.relDistributorUpload(request)
      }
    },
    // 导入渠道
    getChannelParamsUpload(request) {
      let params = {
        file: request.file,
        isUploadFile: true
      }
      if (this.tempId) {
        params.temp_id = this.tempId
      }
      setChannelParamsUpload(params).then(res => {
        let data = res.data.data
        if (data.temp_id) {
          this.$emit('utmUpload', data.temp_id)
        }
      })
    },
    relDistributorUpload(request) {
      let params = {
        file: request.file,
        isUploadFile: true
      }
      if (this.tempId) {
        params.temp_id = this.tempId
      }
      getRelDistributorUpload(params).then(res => {
        let data = res.data.data
        if (data.temp_id) {
          this.$emit('shopUpload', data.temp_id)
        }
      })
    },
    uploadHandleTemplate() {
      let params = {
        file_type: this.category == 'utm' ? 'upload_material_channel_params' : 'upload_material_distributors',
        file_name: '导出模版',
        rel_id: ''
      };
      exportUploadTemplate(params).then((response) => {
        if (response.data.data.file) {
          var a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.$message({
            type: "error",
            message: "没有相关数据可导出",
          });
        }
      });
    },
  },
  mounted() {
  }
}
</script>
<style scoped leng="scss">
.popover-btns {
  margin-top: 10px;
}

.upload-demo {
  width: 98px;
  height: 40px;
  margin-right: 3px;
}

.btnupload {
  width: 98px;
  height: 40px;
}

.upload-btns{
  display: flex;
  align-items: center;
}
</style>