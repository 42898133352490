<template>
  <div>
    <el-row :gutter="20">
      开启状态：<el-switch
        v-model="after_goods_status"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-value="1"
        inactive-value="0"
        @change="changeSettingStatus"
    >
      </el-switch>
      <el-col :span="6">
        <el-button type="primary" class="el-icon-plus" @click="addItems"  size="mini">添加商品</el-button>
      </el-col>
    </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="商品列表" name="all"></el-tab-pane>
        <!-- <el-tab-pane label="未开始" name="waiting"></el-tab-pane>
        <el-tab-pane label="售卖中" name="in_sale"></el-tab-pane>
        <el-tab-pane label="已结束" name="it_has_ended"></el-tab-pane> -->
        <el-table :data="goodsLists" :height="wheight-200" v-loading="loading">
          <!-- <el-table-column prop="old_id" label="ID" width="80"></el-table-column> -->
          <el-table-column prop="item_id" label="ID" min-width="80"></el-table-column>
          <el-table-column prop="item_name" label="商品名称" min-width="180"></el-table-column>
          <el-table-column prop="price" label="销售价" min-width="100"></el-table-column>
          <el-table-column prop="approve_status" label="状态" min-width="100"></el-table-column>
          <el-table-column prop="itemMainCatName" label="商品分类" min-width="150"></el-table-column>
          <el-table-column label="售后说明" width="300">
            <template slot-scope="scope">
              <el-input  type="textarea" v-model="scope.row.instructions" @change="editInstructions(scope.$index, scope.row)" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="remove(scope.row.item_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    <GoodsSelect :items-visible="selectGoodsVisible" :get-status="setItemStatus" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction"></GoodsSelect>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import {
  getAftersalesSettingList,
  updateInstructions,
  getAftersalesSettingStatus,
  addAftersalesSettingGoods,
  changeSettingStatus,
  delAftersalesSettingGoods
} from '@/api/aftersales'
import GoodsSelect from '@/components/goodsSelect'
import {setItemsSort} from "../../../api/goods";

export default {
  props: ['getStatus'],
  provide () {
    return {
      refresh: this.refresh
    }
  },
  components: {
    GoodsSelect
  },
  data () {
    return {
      selectGoodsVisible: false,
      setItemStatus: false,
      relItemsIds: [],
      after_goods_status: 0,
      create_time: '',
      activeName: 'all',
      goodsLists: [],
      downloadfilename: '',
      downloadUrl: '',
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        status: '',
        activity_name: '',
        time_start_begin: '',
        seckill_type: 'limited_time_sale',
        time_start_end: '',
      },
      communityVisible: false,
      couponVisible: false,
      goodsVisible: false,
      goodsList: [],
      goodsCount: 0,
      distributors: [],
      goodsPageSize: 10,
      goodsPage:1,
      nowActivity: '',
      cursymbol: '',
      dialogVisible: false,
      currency: {}
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleClick(tab, event) {
    },
    remove (id) {
      let form = {}
      form = {
        item_id: id
      }
      delAftersalesSettingGoods(form).then(response => {
        this.loading = false
        this.getAftersalesSettingList()
      })
    },
    editInstructions (index, row) {
      updateInstructions({"instructions": row.instructions, "item_id": row.itemId}).then(response => {
        this.getAftersalesSettingList()
      })
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getAftersalesSettingList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getAftersalesSettingList()
    },
    addItems () {
      this.selectGoodsVisible = true
      this.setItemStatus = true
    },
    getAftersalesSettingList () {
      this.loading = true
      getAftersalesSettingList(this.params).then(response => {
        this.goodsLists = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })

      // console.log("this.goodsLists:",this.goodsLists)
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getAftersalesSettingStatus () {
      getAftersalesSettingStatus(this.params).then(response => {
        this.after_goods_status = response.data.data.status
      })
    },
    changeSettingStatus () {
      console.log('after_goods_status', this.after_goods_status)
      let form = {}
      form = {
        after_goods_status: this.after_goods_status,
      }
      console.log('form', form)

      changeSettingStatus(form).then(response => {
        this.after_goods_status = response.data.data.status
      })
    },
    closeItemDialogAction () {
      this.selectGoodsVisible = false
    },
    chooseItemsAction (data) {
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
         data = this.form.recommend_items
       } */
      if (data === null || data.length <= 0) return
      let arr = []//
      data.forEach((item, index) => {
        arr.push(item.item_id)
      })
      this.selectGoodsVisible = false
      console.log('arr_11', arr)
      if (arr.length > 0) {
        let form = {}
        form = {
          itemIds: arr
        }
        addAftersalesSettingGoods(form).then(response => {
          this.getAftersalesSettingList()
        })
      }
    },
    refresh () {
      this.getAftersalesSettingList()
      this.getAftersalesSettingStatus()
    }
  },
  mounted () {
    this.getAftersalesSettingList()
    this.getAftersalesSettingStatus()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getAftersalesSettingList()
    }
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getAftersalesSettingList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.icon-search-plus {
  cursor: pointer;
}
.text-muted {
  color: #999;
}
</style>
