<template>
  <div class="copitext-cell">
    <span @click="handlerClick">
      {{ value }}
    </span>
    <div class="copy-box" @click="handlerCopy">
      <i class="el-icon-document-copy"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopitextCell',
  props: {
    value: String
  },
  methods:  {
    handlerClick() {
      window.open(`${window.location.origin}/distiadmin/order/entitytrade/tradenormalorders?order_id=${this.value}`, '_blank')
    },
    handlerCopy() {
      let value = this.value
      let oInput = document.createElement('input')
      oInput.value = value
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message.success(`复制成功`)
      oInput.remove()
    }
  }
}
</script>

<style lang="scss">
.copitext-cell {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    cursor: pointer;
    color: #0d84ff;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .copy-box {
    cursor: pointer;
    display: block;
    z-index: 100;
    align-items: center;
    width: 15px;
    justify-content: center;
    color: #999;
    margin-left: 4px;
  }
}
.detail-cell-copitext-col:hover {
  .copy-box {
    color: #0d84ff;
  }
}
</style>
