<template>
  <div>
    <el-table :data="priceTemplatesList" :span-method="objectSpanMethod" border :height="wheight-170" v-loading="loading">
      <el-table-column width="50" prop="template_id" label="ID"></el-table-column>
      <el-table-column width="150" prop="name" label="运费模板名称"></el-table-column>
      <el-table-column width="200" label="配送地区">
        <template slot-scope="scope">
          {{scope.row.area|formatCityData(district)}}
        </template>
      </el-table-column>
      <el-table-column prop="up" label="货款下限(元)"></el-table-column>
      <el-table-column prop="down" label="货款上限(元)"></el-table-column>
      <el-table-column prop="basefee" label="运费"></el-table-column>
      <el-table-column width="70" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == true">启用</span>
          <span v-else>关闭</span>
        </template>
      </el-table-column>
      <el-table-column width="120" label="最后修改时间">
        <template slot-scope="scope">
          <span>{{scope.row.updated_at | datetime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div class="operating-icons">
            <i @click="editTemplatesAction(scope.$index, scope.row)" class="iconfont icon-edit1"></i>
            <i @click="deleteTemplatesAction(scope.$index, scope.row)" class="mark iconfont icon-trash-alt1"></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="params.page"
        :total="total_count" :page-size="params.pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {getShippingTemplatesList, deleteShippingTemplates} from '../../../../../api/shipping'
import {getAddress} from '../../../../../api/common'
export default {
  props: ['getStatus'],
  data() {
    return {
      district: {},
      loading: false,
      templateName: '',
      priceTemplatesList: [],
      total_count: 0,
      params: {
        is_free: 0,
        valuation: 3,
        page: 1,
        pageSize: 20
      }
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleCurrentChange(pageNum) {
      this.params.page = pageNum
    },
    getShippingTemplatesList () {
      this.loading = true
      getShippingTemplatesList(this.params).then(response => {
        this.priceTemplatesList = [];
        var temp = []
        for (var item in response.data.data.list) {
          response.data.data.list[item].fee_conf = JSON.parse(response.data.data.list[item].fee_conf)
          temp[response.data.data.list[item].template_id] = []
          for (var conf_fee_item in response.data.data.list[item].fee_conf) {
            for (var rules_item in response.data.data.list[item].fee_conf[conf_fee_item].rules) {
              temp[response.data.data.list[item].template_id].push({
                template_id: response.data.data.list[item].template_id,
                name: response.data.data.list[item].name,
                count: '0',
                area: response.data.data.list[item].fee_conf[conf_fee_item].area,
                up: response.data.data.list[item].fee_conf[conf_fee_item].rules[rules_item].up,
                down: response.data.data.list[item].fee_conf[conf_fee_item].rules[rules_item].down,
                basefee: response.data.data.list[item].fee_conf[conf_fee_item].rules[rules_item].basefee,
                status: response.data.data.list[item].status,
                updated_at: response.data.data.list[item].update_time
              })
            }
          }
        }
        for(var i in temp) {
          for(var j in temp[i]) {
            if (0 == j) {
              temp[i][j].count = temp[i].length
            }
            this.priceTemplatesList.push(temp[i][j])
          }
        }
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    editTemplatesAction(index, row) {
      this.$router.push({path: this.matchHidePage('editor/') + row.template_id})
    },
    deleteTemplatesAction(index, row) {
      this.$confirm('此操作将删除该运费模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteShippingTemplates(row.template_id).then(response => {
          this.priceTemplatesList.splice(index, 1)
          this.$message({
            message: '删除运费模板成功',
            type: 'success',
            duration: 5 * 1000
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      var arrCol = [0, 1, 6, 7, 8]
      if (arrCol.indexOf(columnIndex) > -1) {
        if (row.count > 0) {
          return {
            rowspan: row.count,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    getAddress() {
      getAddress().then(res => {
        this.district = res.data.data
      })
    }
  },
  mounted() {
    this.getAddress()
    this.getShippingTemplatesList()
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getShippingTemplatesList()
      }
    }
  }
}
</script>

<style scoped>

</style>
