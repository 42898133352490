import fetch from '@/utils/fetch'

/** 获取按钮列表 */
export function getBtnList(){
  return fetch({
    url: '/order/btn/lists',
    method: 'get',
    params: {}
  })
}

/**
 * @description 新建按钮
 * @param {String} data.order_status 订单状态
 * @param {String} data.btn_name 按钮名称
 * @param {String} data.is_highlight 是否高亮
 * @param {String} data.start_time 开始时间
 * @param {String} data.end_time 结束时间
 * @param {String} data.btn_path 按钮路径
 * @param {String} data.btn_rel_item 关联商品
*/
export function createBtn (data) {
  return fetch({
    url: '/order/btn/create',
    method: 'post',
    data
  })
}

/**
 * @description 修改按钮
*/
export function updateBtn (data) {
  const { id, ...rest } = data
  return fetch({
    url: `/order/btn/update/${id}`,
    method: 'post',
    data: rest
  })
}

/**
 * @description 修改按钮排序
 * @param {data} data 每次只传一个order_status的分组数据
*/
export function updateBtnSort (data) {
  return fetch({
    url: '/order/btn/update_sort',
    method: 'post',
    data:{
      data: JSON.stringify(data)
    }
  })
}

/**
 * @description 删除按钮
 * @param {data} data 每次只传一个order_status的分组数据
*/
export function deleteBtn (id) {
  return fetch({
    url: '/order/btn/delete',
    method: 'post',
    data:{
      id
    }
  })
}

// 详情获取商品列表数据
export function getGoodsList(item_id) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: {
      page: 1,
      pageSize: 99999,
      item_type: 'normal',
      item_id
    }
  })
}
