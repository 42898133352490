<template>
  <div>

    <el-form :inline="true" label-width="100px" v-show="isShow">
      <el-form-item label="订单号">
        <el-input placeholder="请输入合法订单号" v-model="order_id" clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="jumpRoute('detail')" :disabled="isDisable">查看详情</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="info" @click="jumpRoute('process')" :disabled="isDisable">查看日志</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="warning" @click="jumpRoute('after_sales')" :disabled="isDisableAfterSales">查看售后</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="danger" @click="jumpRoute('after_refund')" :disabled="isDisableAfterRefund">查看退款</el-button>
      </el-form-item>

    </el-form>


    <router-view></router-view>
  </div>
</template>

<script>
import {getOrderDetail} from '../../../../api/trade'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'login_type'
    ])
  },
  data() {
    return {
      order_id: '',
      isShow: true,
      isDisable: false,
      isDisableAfterRefund: false,
      isDisableAfterSales: false,
      orderInfo: {},
      aftersalesInfo: {},
      refundInfo: {},
    }
  },

  methods: {
    jumpRoute(view) {

      switch (this.login_type) {
        case 'disti': // 代理端
          this.prefix = '/distiadmin'
          break;
        case 'dealer': // 经销商端
          this.prefix = '/dealeradmin'
          break;
        case 'distributor': // 店铺端
          this.prefix = '/shopadmin';
          break;
        default: // 平台  包括admin  staff
          this.prefix = '';
      }
      let path = this.prefix + '/order/entitytrade/QueryOrder/QueryOrder' + '_' + view;
      if (this.order_id) {
        getOrderDetail(this.order_id).then((response) => {
          this.orderInfo = response.data.data.orderInfo
          this.aftersalesInfo = response.data.data.aftersalesInfo
          this.refundInfo = response.data.data.refundInfo;
          console.log(this.orderInfo, '-------------------------');
          if (this.orderInfo.length <= 0) {
            this.$message.error('订单号不存在！');
            return false;
          }
          switch (view) {
            case 'after_sales':
              console.log(this.aftersalesInfo, '===================');

              if (this.aftersalesInfo.length <= 0) {
                this.$message.error('售后单不存在！');

                return false;
              }
              path += '?aftersales_bn=' + this.aftersalesInfo.aftersales_bn;
              break;
            case 'after_refund':
              console.log(this.refundInfo, '+++++++++++++++');

              if (this.refundInfo.length <= 0) {
                this.$message.error('退款单不存在！');

                return false;
              }
              path += '?refund_bn=' + this.refundInfo.refund_bn;
              break;
            default :
              path += '?orderId=' + this.orderInfo.order_id;
              break;
          }

          this.$router.push({
            path,
            query: {
              'is_view': true,
            }
          });
        })
      } else {
        this.$message.error('请输入订单号！');
      }
    },
  }
}
</script>
