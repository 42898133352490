<template>
  <div>
    <el-row>
      <el-input class="input-b" placeholder="活动标题" v-model="params.material_name" @change="searchList">
      </el-input>
      <el-select class="input-m mt10" placeholder="请选择物料类型" v-model="params.material_type" clearable
        @change="searchList">
        <el-option label="单页面" :value="'page'"></el-option>
        <el-option label="抽奖活动" :value="'lottery'"></el-option>
        <el-option label="门店活动" :value="'offline_activity'"></el-option>
      </el-select>
      <el-button type="primary" icon="plus" @click="addCreateMaterial">
        创建物料
      </el-button>
    </el-row>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生效中" name="going"></el-tab-pane>
      <el-tab-pane label="已失效" name="stopped"></el-tab-pane>

      <el-table :data="materialList" style="width: 100%" :height="wheight - 270" v-loading="loading"
        element-loading-text="数据加载中">
        <el-table-column prop="id" width="100" label="物料ID"></el-table-column>
        <el-table-column prop="material_name" label="物料名称"></el-table-column>
        <el-table-column label="物料状态">
          <template slot-scope="scope">
            <span>
              {{ scope.row.status == '1' && !scope.row.expired ? '生效中' : '已失效'}}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="物料类型">
          <template slot-scope="scope">
            <span>{{ scope.row.material_type == 'page' ? '单页面' : '' }}</span>
            <span>{{ scope.row.material_type == 'lottery' ? '抽奖活动' : '' }}</span>
            <span>{{ scope.row.material_type == 'offline_activity' ? '门店活动' : '' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="小程序">
          <template slot-scope="scope">
            <span>{{ scope.row.program == 'wechat_order' ? '微信专营店商城' : '' }}</span>
            <span>{{ scope.row.program == 'wechat_zyd' ? '微信专营店' : '' }}</span>
            <span>{{ scope.row.program == 'bytemini_zyd' ? '抖音专营店' : '' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="失效时间">
          <template slot-scope="scope">
            <div v-if="scope.row.expire_time != '0'">
              {{ scope.row.expire_time | datetime("yyyy-MM-dd hh:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.created | datetime("yyyy-MM-dd hh:mm:ss") }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="exportRecordMaterial(scope.row)">
              导出
            </el-button>
            <el-button type="text" @click="routrePushStatistics(scope.row)">
              明细统计
            </el-button>
            <el-popover placement="right" trigger="click" v-model="scope.row.popoverVisible"
              :key="scope.row.activity_id + 'popover'">
              <div>
                <el-date-picker v-model="endItem" type="datetime" value-format="timestamp"
                  placeholder="选择日期时间"></el-date-picker>
                <div class="popover-btns">
                  <el-button size="mini" @click="scope.row.popoverVisible = false">取 消</el-button>
                  <el-button size="mini" type="primary" @click="upExpireTime(scope.row)">确 定</el-button>
                </div>
              </div>
              <el-button type="text" slot="reference">修改失效时间</el-button>
            </el-popover>
            <el-button type="text" @click="onCancellation(scope.row)">
              作废
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-padded content-center">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
        </el-pagination>
      </div>
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getMaterialList, getMaterialExpireTime, getDeleteTempDetailList, getMaterialCancel } from '@/api/material'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {},
  data() {
    return {
      activeName: 'going',
      loading: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 20,
        material_name: '',
        material_type: ''
      },
      total_count: 0,
      materialList: [],
      endItem: ''
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.page = 1
      this.getOfflineActivityList()
    },
    // 创建物料
    addCreateMaterial() {
      this.$router.push({ path: this.matchInternalRoute('/marketing/material/materialcreate') })
    },
    exportRecordMaterial(row) {
      let filter = {
        material_id: row.id,
        is_export: '1'
      }
      getDeleteTempDetailList(filter).then(response => {
        this.$message({
          message: '导出成功',
          type: 'success'
        });
      })
    },
    routrePushStatistics(row) {
      this.$router.push({ path: this.matchInternalRoute('/marketing/material/materialstatistics'), query: { id: row.id } })
    },
    onCancellation(row) {
      getMaterialCancel({id: row.id}).then(res => {
        this.getOfflineActivityList()
      })
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getOfflineActivityList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    searchList() {
      this.params.page = 1
      this.getOfflineActivityList()
    },
    upExpireTime(item) {
      if (!this.endItem) {
        this.$message({
          message: '请选择时间',
          type: 'warning'
        });
        return
      }
      let params = {
        id: item.id,
        expire_time: this.endItem / 1000
      }
      getMaterialExpireTime(params).then(res => {
        this.endItem = ''
        this.getOfflineActivityList()
      })
    },
    getOfflineActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getMaterialList(filter).then(response => {
        let data = response.data.data
        this.materialList = data.list
        this.total_count = data.total_count
        this.loading = false
      }).catch(error => {
        this.materialList = []
        this.total_count = 0
        this.loading = false
      })
    },
    expireTimeType(time) {
      let newTime = new Date().getTime() / 1000
      if (newTime > Number(time)) {
        return '已失效'
      } else {
        return '生效中'
      }
    }
  },
  filters: {
    materialType(type) {
      if (type == 'page') {
        return '单页面'
      } else if (type == 'lottery') {
        return '抽奖活动'
      } else if (type == 'offline_activity') {
        return '门店活动'
      }
    }
  },
  mounted() {
    this.getOfflineActivityList()
  }
}
</script>
<style scoped leng="scss">
.popover-btns {
  margin-top: 10px;
}
</style>
