import { createSetting } from '@shopex/finder'
import { batchDelete, deleteItem, closeOrder, retryCancelOrder } from '../api'
import { formatTimeStampToStr } from '@/utils/index'
import { MessageBox } from 'element-ui'
import CopitextCell from '../component/copitext-cell.vue'

export const StatusOption = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '未处理',
    value: '0'
  },
  {
    label: '处理失败',
    value: '2'
  },
  {
    label: '处理成功',
    value: '1'
  }
]

const ITEM_STATUS_ENUM = {
  0: '未处理',
  1: '处理成功',
  2: '处理失败',
}

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '导入时间',
      key: 'importTime',
      type: 'date-time-picker',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
    {
      name: '订单编码',
      key: 'order_id',
    }
  ],
  columns: [
    {
      name: '导入订单编码',
      key: 'order_id',
      minWidth: 150,
      render(_, {row}) {
        return <CopitextCell value={row.order_id} />
      }
    },
    {
      name: '导入订单门店',
      key: 'distributor_name',
      minWidth: 150,
      render(_, {row}) {
        return (
          <div>
            <div>{row.distributor_name}</div>
            <div>{row.shop_code}</div>
          </div>
        )
      }
    },
    {
      name: '处理状态',
      key: 'status',
      minWidth: 100,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '处理完成时间',
      key: 'finish_time',
      minWidth: 150,
      render(_, {row}) {
        if(!row.finish_time) return '-'
        return formatTimeStampToStr(row.finish_time)
      }
    },
    { name: '导入时间', key: 'created', formatter: v => v && formatTimeStampToStr(v), minWidth: 150 },
    { name: '处理结果描述', key: 'result_desc', minWidth: 150 },
    { name: '关单原因', key: 'reason' }
  ],
  actions: [
    {
      name: '导入风险订单',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportAddDialog()
        }
      }
    },
    {
      name: '导出风险订单',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '关闭风险订单',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          MessageBox.confirm('请确认是否关闭风险订单？', '提示').then(async () => {
            const ids = rows.map(v => v.id)
            if(!ids.length) {
              vm.$message.error('请选择要关闭的风险订单')
              return
            }
            await closeOrder(ids)
            vm.$message.success('关闭成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          MessageBox.confirm('请确认是否批量删除？', '提示').then(async () => {
            const ids = rows.map(v => v.id)
            if(!ids.length) {
              vm.$message.error('请选择要删除的风险订单')
              return
            }
            await batchDelete(ids)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          MessageBox.confirm('请确认是否删除？', '提示').then(async () => {
            const id = rows[0].id
            await deleteItem(id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '重试',
      type: 'button',
      buttonType: 'text',
      visible: (row) => row.status === '2', // 只有处理失败状态可以重试
      action: {
        handler: async (rows) => {
          const id = rows[0].id
          await retryCancelOrder(id)
          vm.$message.success('重试成功')
          vm.refresh()
        }
      }
    }
  ]
})
