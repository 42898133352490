<!--
 * @Author: Arvin
 * @GitHub: https://github.com/973749104
 * @Blog: https://liuhgxu.com
 * @Description: 分账设置
 * @FilePath: /app/src/view/mall/fenzhang/setting.vue
 * @Date: 2020-09-21 17:50:26
 * @LastEditors: Arvin
 * @LastEditTime: 2020-09-22 11:46:35
-->
<template>
  <el-form label-width="200px" label-suffix="：" class="fenzhang">
    <el-form-item label="店铺账号提现金额设置">
      满
      <el-input-number   :min="0" :max="9999999" :precision='0' v-model="form.distributor_money" size="mini" controls-position="right"/>
元，自动提现
    </el-form-item>
    <h4>提现设置说明：</h4>
    <div class="frm-tips">
      1、提现起始金额设置为大于等于0的整数
    </div>
    <div class="frm-tips">
      2、每日凌晨3点自动对满足条件的店铺账号进行提现
    </div>
    <div class="frm-tips">
      3、根据支付公司逻辑，提现后一个工作日到账，节假日顺延
    </div>

    <div class="section-footer with-border content-center">
      <el-button type="primary" v-loading="loading" @click="onSubmit">保存</el-button>
    </div>
  </el-form>
</template>

<script>
import { getWithdrawset, saveWithdrawset } from '@/api/fenzhang'

export default {
  name: 'fenzhang',
  data() {
    1
    return {
      form: {
        // 是否开启分账
      
        // 费率
        distributor_money: '0.00',
       
      },
      loading: false,
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    // 获取配置数据
    async getDetail() {
      const res = await getWithdrawset()
      let data = res.data.data
      this.form = JSON.parse(JSON.stringify(data))
    },
    // 费率输入

    async onSubmit() {
      let {  distributor_money} = this.form
      let params = {
        distributor_money,
      }
      const res = await saveWithdrawset(params)
      this.$message({
        type: 'success',
        message: '保存成功',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.fenzhang {
  width: 100%;
  .businessType {
    display: flex;
    flex-direction: column;
    .radio {
      margin-top: 25px;
      &:first-child {
        margin-top: 13px !important;
      }
    }
  }
  .el-input {
    width: 240px;
    &.first {
      width: 80px;
      margin-right: 10px;
    }
  }
  .frm-tips {
    display: block;
  }
  h3 {
    padding-left: 18px;
  }
}
</style>


