<template>
  <div class="section-white">
    <div class="detail-info" v-loading="loading">
      <h3 class="title">订单详情</h3>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <h3>订单信息</h3>
        </div>
        <el-row>
          <el-col :span="3" class="content-right"><h4>订单号：</h4></el-col>
          <el-col :span="5"> {{ orderInfo.order_id }}</el-col>

          <el-col :span="3" class="content-right"><h4>订单类型：</h4></el-col>
          <el-col :span="5">
            <span v-if="orderInfo.order_type==='normal'"> 实体订单 </span>
          </el-col>

          <el-col :span="3" class="content-right"><h4>订单状态：</h4></el-col>
          <el-col :span="5">
            <el-tag>{{ orderInfo.order_status_msg }}</el-tag>
          </el-col>
        </el-row>

        <el-row v-if="orderInfo.edu_order_no">
          <el-col :span="3" class="content-right"><h4>教育优惠订单号：</h4></el-col>
          <el-col :span="5"> {{ orderInfo.edu_order_no }}</el-col>
        </el-row>

        <el-row v-if="memberInfo">
          <el-col :span="3" class="content-right"><h4>收货人手机：</h4></el-col>
          <el-col :span="5">
            {{ orderInfo.receiver_mobile }}
            <PiiInfo pageType='1' memberType='receiver_mobile' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
          </el-col>
          <el-col :span="3" class="content-right"><h4>下单时间：</h4></el-col>
          <el-col :span="5">
            <span style="color: #E6A23C">{{ orderInfo.create_time | datetime('yyyy-MM-dd')}}&nbsp;</span>
            <span>{{ orderInfo.create_time | datetime('hh:mm:ss') }}</span>

          </el-col>
          <el-col :span="3" class="content-right"><h4>更新时间：</h4></el-col>
          <el-col :span="5">{{ orderInfo.update_time | datetime('yyyy-MM-dd hh:mm:ss') }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="content-right"><h4>商品金额：</h4></el-col>
          <el-col :span="5">{{ orderInfo.fee_symbol }}{{ orderInfo.order_type != 'bargain' ? orderInfo.item_fee / 100 : orderInfo.item_price / 100 }}</el-col>

          <el-col :span="3" class="content-right" v-if="orderInfo.discount_fee"><h4>优惠总金额：</h4></el-col>
          <el-col :span="5" v-if="orderInfo.discount_fee">-{{ orderInfo.fee_symbol }}{{ orderInfo.discount_fee ? orderInfo.discount_fee / 100 : 0 }}
            <span class="frm-tips">(包含优惠券，满折，满减等优惠金额)</span>
          </el-col>

          <el-col :span="3" class="content-right"><h4>应付总金额：</h4></el-col>
          <el-col :span="5">{{ orderInfo.fee_symbol }}{{ orderInfo.total_fee / 100 }}</el-col>
          <el-col :span="3" class="content-right"><h4>旧机抵扣金额：</h4></el-col>
          <el-col :span="5">{{ orderInfo.fee_symbol }}{{ orderInfo.tradein_fee / 100 }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="content-right"><h4>流水号：</h4></el-col>
          <el-col :span="5">
            <el-tag type="warning" effect="plain" :hit="true" size="medium">&nbsp;&nbsp;{{orderInfo.serial_number }}&nbsp;&nbsp;</el-tag>

          </el-col>

        </el-row>

        <el-row v-if="orderInfo.coupon_discount">
          <el-col :span="3" class="content-right" ><h4>优惠券减免：</h4></el-col>
          <el-col :span="5">- {{ orderInfo.fee_symbol }}{{ orderInfo.coupon_discount / 100 }}</el-col>
        </el-row>

        <!-- <el-row v-if="orderInfo.remark">
          <el-col :span="3" class="content-right"><h4>订单备注：</h4></el-col>
          <el-col :span="12">{{ orderInfo.remark }}</el-col>
        </el-row> -->

        <el-row v-if="cancelData && cancelData.cancel_from === 'shop' && cancelData.cancel_reason">
          <el-col :span="3" class="content-right"><h4>商家关闭原因：</h4></el-col>
          <el-col :span="12">{{cancelData.cancel_reason}}</el-col>
        </el-row>

      </el-card>

      <el-card shadow="never" v-if="orderInfo.discount_info && orderInfo.discount_info.length > 0">
        <div slot="header" class="clearfix">
          <h3>优惠明细</h3>
        </div>
        <el-row v-for="(item, index) in orderInfo.discount_info" :key="index">
          <template v-if="item.discount_fee > 0">
            <el-col :span="4" class="content-right">
              <el-tag v-if="item.type === 'cash_discount'" size="mini" type="danger">抵扣券</el-tag>
              <el-tag v-if="item.type === 'coupon_discount'" size="mini" type="success">折扣券</el-tag>
              <el-tag v-if="item.type === 'bundle_discount'" size="mini">优惠搭售</el-tag>
              {{ item.info || item.activity_title}}：
            </el-col>
            <el-col :span="19" v-if="item.discount_fee > 0">优惠 {{ orderInfo.fee_symbol }}{{ item.discount_fee / 100 }} &nbsp;&nbsp; <span class="frm-tips">{{ item.rule }}</span>
            </el-col>
          </template>
        </el-row>
      </el-card>
      <el-card shadow="never" v-if="orderInfo.full_gift && orderInfo.full_gift.length > 0">
        <div slot="header" class="clearfix">
          <h3>满赠信息</h3>
        </div>
        <el-table  :data="orderInfo.full_gift"  stripe border style="width: 100%">
          <el-table-column  prop="title" label="活动名称" width="300"></el-table-column>
          <el-table-column  prop="info" label="活动说明" width="300"></el-table-column>
          <el-table-column  prop="coupon_name" label="赠品" width="300"></el-table-column>
          <el-table-column  prop="num" label="数量" width="80"></el-table-column>
          <el-table-column  prop="send_status" label="状态" >
            <template slot-scope="scope">
              <el-tag  v-if="scope.row.send_status_en==='pending'" :hit="false" effect="plain" type="info">{{scope.row.send_status}}</el-tag>
              <el-tag  v-else-if="scope.row.send_status_en ==='success'" :hit="false" effect="plain"  type="success" >{{scope.row.send_status}}</el-tag>
              <el-tag  v-else  effect="plain" :hit="false" type="danger">{{scope.row.send_status}}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-card shadow="never" v-if="tcb_order.list">
        <div slot="header" class="clearfix">
          <h3>旧机抵扣信息</h3>
        </div>
        <el-row v-if="tcb_order.list.model.brand.name">
          <el-col :span="3" class="content-right"><h4>品牌：</h4></el-col>
          <el-col :span="12">{{ tcb_order.list.model.brand.name }}</el-col>
        </el-row>
        <el-row v-if="tcb_order.list.model.model.name">
          <el-col :span="3" class="content-right"><h4>名称：</h4></el-col>
          <el-col :span="12">{{ tcb_order.list.model.model.name }}</el-col>
        </el-row>
        <el-row v-if="tcb_order.list.detectSku">
          <el-col :span="3" class="content-right"><h4>规格：</h4></el-col>
          <el-col :span="12">{{ tcb_order.list.detectSku }}</el-col>
        </el-row>
        <el-row v-if="tcb_order.list.userPrice">
          <el-col :span="3" class="content-right"><h4>⽤户评估⾦额：</h4></el-col>
          <el-col :span="1" class="content-right">{{ orderInfo.fee_symbol }}{{ tcb_order.list.userPrice  / 100 }}</el-col>
        </el-row>

        <el-row v-if="tcb_order.list.detectPrice">
          <el-col :span="3" class="content-right"><h4>检测评估⾦额：</h4></el-col>
          <el-col :span="1" class="content-right">{{ orderInfo.fee_symbol }}{{ tcb_order.list.detectPrice / 100 }}</el-col>
        </el-row>
        <el-row v-if="tcb_order.list.statusName">
          <el-col :span="3" class="content-right"><h4>状态：</h4></el-col>
          <el-col :span="12">{{ tcb_order.list.statusName }}</el-col>
        </el-row>

        <el-row v-if="tcb_order_aftersale.paymentStatusName">
          <el-col :span="3" class="content-right"><h4>售后状态：</h4></el-col>
          <el-col :span="12">{{ tcb_order_aftersale.paymentStatusName }}</el-col>
        </el-row>

        <el-row v-if="orderInfo.tradein_service_type == 2">
          <el-col :span="3" class="content-right"><h4>上门时间：</h4></el-col>
          <!-- <el-col :span="12"> {{ orderInfo.tradein_service_info.serviceTime | datetime('yyyy 年 MM 月 dd 日') }}</el-col> -->
          <el-col :span="12"> {{ orderInfo.tradein_service_info.serviceTimeRange }}</el-col>
        </el-row>

        <el-row v-if="orderInfo.tradein_service_type == 2">
          <el-col :span="3" class="content-right"><h4>上门地址：</h4></el-col>
          <el-col :span="12">{{ orderInfo.tradein_service_info.province }}{{ orderInfo.tradein_service_info.city }}{{ orderInfo.tradein_service_info.area }}{{ orderInfo.tradein_service_info.address }}</el-col>
        </el-row>
      </el-card>


      <el-card shadow="never" v-if="tradeInfo.tradeId && tradeInfo.tradeState != 'NOTPAY'">
        <div slot="header" class="clearfix">
          <h3>支付信息</h3>
        </div>
        <div class="section-body">
          <el-row>
            <el-col :span="3" class="content-right"><h4>交易单号：</h4></el-col>
            <el-col :span="5">{{ tradeInfo.tradeId }}</el-col>
            <el-col :span="3" class="content-right"><h4>支付状态：</h4></el-col>
            <el-col :span="5">
              <span v-if="tradeInfo.tradeState =='SUCCESS'"> 支付成功</span>
              <span v-else-if="tradeInfo.tradeState =='REFUND'">转入退款</span>
              <span v-else-if="tradeInfo.tradeState =='CANCEL_CLOSED'">已取消</span>
              <span v-else-if="tradeInfo.tradeState =='REFUND_SUCCESS'">退款成功</span>
              <span v-else-if="tradeInfo.tradeState =='NOTPAY'">未支付</span>
              <span v-else-if="tradeInfo.tradeState =='CLOSED'"> 已关闭</span>
              <span v-else-if="tradeInfo.tradeState =='CLOSING'"> 已关闭</span>
              <span v-else-if="tradeInfo.tradeState =='REVOKED'">已撤销</span>
              <span v-else-if="tradeInfo.tradeState =='PAYERROR'">支付失败(其他原因，如银行返回失败)</span>
            </el-col>
            <el-col :span="3" class="content-right"><h4>交易流水号：</h4></el-col>
            <el-col :span="5">{{ tradeInfo.transactionId }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3" class="content-right"><h4>实付金额(RMB)：</h4></el-col>
            <el-col :span="5" v-if="tradeInfo.payType =='point'">0</el-col>
            <el-col :span="5" v-else>￥{{ tradeInfo.payFee / 100 }}</el-col>
            <div v-if="tradeInfo.payType =='point'">
              <el-col :span="3" class="content-right"><h4>支付积分：</h4></el-col>
              <el-col :span="5">{{ tradeInfo.payFee }}</el-col>
            </div>
            <el-col :span="3" class="content-right"><h4>支付方式：</h4></el-col>
            <el-col :span="5">
              <span v-if="tradeInfo.payType =='amorepay'"> 微信支付</span>
              <span v-if="tradeInfo.payType =='wxpaypc'"> 微信PC支付</span>
              <span v-if="tradeInfo.payType =='wxpay'"> 微信支付</span>
              <span v-if="tradeInfo.payType =='wxpayh5'"> 微信H5支付</span>
              <span v-if="tradeInfo.payType =='alipay'"> 支付宝支付</span>
              <span v-if="tradeInfo.payType =='alipayh5'"> 支付宝H5支付</span>
              <span v-else-if="tradeInfo.payType =='deposit'"> 余额支付</span>
              <span v-else-if="tradeInfo.payType =='point'"> 积分支付</span>
              <span v-else-if="tradeInfo.payType =='pos'"> POS银行卡支付</span>
              <span v-else-if="tradeInfo.payType =='wxpaypos'"> 微信扫码支付</span>
              <span v-else-if="tradeInfo.payType =='alipaypos'"> 支付宝扫码支付</span>
              <span v-else-if="tradeInfo.payType =='adapay'"> 微信支付</span>
              <span v-else-if="tradeInfo.payType =='adapay_fq_hua_bei'"> 花呗支付</span>
              <span v-else-if="tradeInfo.payType =='adapay_fq_credit_card'"> 支付宝信用卡分期</span>
            </el-col>
            <el-col :span="3" class="content-right"><h4>实际支付方式：</h4></el-col>
            <el-col :span="5">{{ tradeInfo.fund_channel_msg }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3" class="content-right" v-if="tradeInfo.couponFee > 0"><h4>微信卡包代金券：</h4></el-col>
            <el-col :span="5" v-if="tradeInfo.couponFee > 0">¥{{ tradeInfo.couponFee / 100 }}</el-col>
            <el-col :span="3" class="content-right"><h4>交易时间：</h4></el-col>
            <el-col :span="5">{{ tradeInfo.timeStart | datetime('yyyy-MM-dd hh:mm:ss') }}</el-col>
            <el-col :span="3" class="content-right"><h4>交易结束时间：</h4></el-col>
            <el-col :span="5">{{ tradeInfo.timeExpire | datetime('yyyy-MM-dd hh:mm:ss') }}</el-col>
          </el-row>
        </div>

        <div v-if="orderInfo.tradein_fee > 0 && tradeInfoTradein.tradeId > 0" class="section-body">
          <el-row>
            <el-col :span="3" class="content-right"><h4>交易单号：</h4></el-col>
            <el-col :span="5">{{ tradeInfoTradein.tradeId }}</el-col>
            <el-col :span="3" class="content-right"><h4>支付状态：</h4></el-col>
            <el-col :span="5">
              <span v-if="tradeInfoTradein.tradeState =='SUCCESS'"> 支付成功</span>
              <span v-else-if="tradeInfoTradein.tradeState =='REFUND'">转入退款</span>
              <span v-else-if="tradeInfoTradein.tradeState =='CANCEL_CLOSED'">已取消</span>
              <span v-else-if="tradeInfoTradein.tradeState =='REFUND_SUCCESS'">退款成功</span>
              <span v-else-if="tradeInfoTradein.tradeState =='NOTPAY'">未支付</span>
              <span v-else-if="tradeInfoTradein.tradeState =='CLOSED'"> 已关闭</span>
              <span v-else-if="tradeInfoTradein.tradeState =='CLOSING'"> 已关闭</span>
              <span v-else-if="tradeInfoTradein.tradeState =='REVOKED'">已撤销</span>
              <span v-else-if="tradeInfoTradein.tradeState =='PAYERROR'">支付失败(其他原因，如银行返回失败)</span>
            </el-col>
            <el-col :span="3" class="content-right"><h4>交易流水号：</h4></el-col>
            <el-col :span="5">{{ tradeInfoTradein.transactionId }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="3" class="content-right"><h4>实付金额(RMB)：</h4></el-col>
            <el-col :span="5" v-if="tradeInfoTradein.payType =='point'">0</el-col>
            <el-col :span="5" v-else>￥{{ tradeInfoTradein.payFee / 100 }}</el-col>
            <div v-if="tradeInfoTradein.payType =='point'">
              <el-col :span="3" class="content-right"><h4>支付积分：</h4></el-col>
              <el-col :span="5">{{ tradeInfoTradein.payFee }}</el-col>
            </div>
            <el-col :span="3" class="content-right"><h4>支付类型：</h4></el-col>
            <el-col :span="5">
              <span v-if="tradeInfoTradein.payType =='amorepay'"> 微信支付</span>
              <span v-if="tradeInfoTradein.payType =='wxpaypc'"> 微信PC支付</span>
              <span v-if="tradeInfoTradein.payType =='wxpay'"> 微信支付</span>
              <span v-if="tradeInfoTradein.payType =='wxpayh5'"> 微信H5支付</span>
              <span v-if="tradeInfoTradein.payType =='alipay'"> 支付宝支付</span>
              <span v-if="tradeInfoTradein.payType =='alipayh5'"> 支付宝H5支付</span>
              <span v-else-if="tradeInfoTradein.payType =='deposit'"> 余额支付</span>
              <span v-else-if="tradeInfoTradein.payType =='point'"> 积分支付</span>
              <span v-else-if="tradeInfoTradein.payType =='pos'"> POS银行卡支付</span>
              <span v-else-if="tradeInfoTradein.payType =='wxpaypos'"> 微信扫码支付</span>
              <span v-else-if="tradeInfoTradein.payType =='alipaypos'"> 支付宝扫码支付</span>
              <span v-else-if="tradeInfoTradein.payType =='adapay'"> AdaPay汇付支付</span>
              <span v-else-if="tradeInfoTradein.payType =='tradein'"> 旧机抵扣</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3" class="content-right" v-if="tradeInfoTradein.couponFee > 0"><h4>微信卡包代金券：</h4></el-col>
            <el-col :span="5" v-if="tradeInfoTradein.couponFee > 0">¥{{ tradeInfoTradein.couponFee / 100 }}</el-col>
            <el-col :span="3" class="content-right"><h4>交易时间：</h4></el-col>
            <el-col :span="5">{{ tradeInfoTradein.timeStart | datetime('yyyy-MM-dd hh:mm:ss') }}</el-col>
            <el-col :span="3" class="content-right"><h4>交易结束时间：</h4></el-col>
            <el-col :span="5">{{ tradeInfoTradein.timeExpire | datetime('yyyy-MM-dd hh:mm:ss') }}</el-col>
          </el-row>
        </div>
      </el-card>
      <!--
      <el-card shadow="never" v-if="tradeInfo.tradeId && tradeInfo.tradeState != 'NOTPAY'">
        <div slot="header" class="clearfix">
          <h3>旧机抵扣支付信息</h3>
        </div>

      </el-card>
             -->
      <el-card shadow="never" v-if="orderInfo.invoice">
        <div slot="header" class="clearfix">
          <h3>发票信息</h3>
        </div>
        <el-card shadow="never">
          <div class="section-body" style="display:flex;">
            <div style="width:50%">
              <el-row>
                <el-col :span="6" class="content-right"><h4>发票类型：</h4></el-col>
                <el-col :span="10"><span v-if="orderInfo.invoice && orderInfo.invoice.type == '0'"> 企业 </span><span v-else-if="orderInfo.invoice && orderInfo.invoice.type != '0'"> 个人 </span><span v-else>---</span></el-col>
              </el-row>

              <el-row>
                <el-col :span="6" class="content-right"><h4>接收方式：</h4></el-col>
                <el-col :span="10"><span v-if="orderInfo.invoice && orderInfo.invoice.receiveType == 'paper_invoice'"> 纸质 </span><span v-else-if="orderInfo.invoice && orderInfo.invoice.receiveType != 'paper_invoice'"> 电子 </span><span v-else>---</span></el-col>
              </el-row>

              <el-row>
                <el-col :span="6" class="content-right"><h4>抬头名称：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.title : '---' }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="content-right"><h4>税号：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.taxNumber : '---' }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="content-right"><h4>地址：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.companyAddress : '---' }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="content-right"><h4>电话：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.telephone : '---' }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="content-right"><h4>开户银行：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.bankName : '---' }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="content-right"><h4>银行账号：</h4></el-col>
                <el-col :span="10">{{ orderInfo.invoice ? orderInfo.invoice.bankAccount : '---' }}</el-col>
              </el-row>
            </div>
            <div style="width:50%">
              <el-row style="display:flex;align-items:center">
                <el-col :span="6" :offset="2" class="content-right"><h4>发票状态：</h4></el-col>
                <el-col :span="5">
                  <el-tag size="medium">
                    {{ orderInfo.is_invoiced ? '已开票' : orderInfo.is_red_invoice ? '已红冲' : '开票中' }}
                  </el-tag>
                </el-col>
              </el-row>
              <el-row style="display:flex;">
                <el-col :span="6" :offset="2" class="content-right content-row"><h4>发票明细：</h4></el-col>
                <el-col :span="15">
                  <div class="invoice-detail" v-for="(item, index) in orderInfo.invoice_files" :key="index">
                    <el-tooltip class="item" effect="dark" :content="item.file_name" placement="left">
                      <div class="invoice-file-name" @click="showInvoiceChange(item)"><i class="el-icon-document"></i>{{item.file_name}}</div>
                    </el-tooltip>
                    <!-- <span v-if="login_type !== 'distributor' && (orderInfo.invoice_source == 0)" class="deletd-btn" @click="deleteInvoiceChange(item)">删除</span> -->
                  </div>
                  <!-- <el-button
                    v-if="login_type !== 'distributor' && (orderInfo.invoice_source == 0 && orderInfo.order_status == 'DONE')"
                    type="primary"
                    size="medium"
                    class="upload-btn"
                    @click="updateInvoiceBtnChange">
                      {{orderInfo.invoice_files && orderInfo.invoice_files.length > 0 ? '更新发票信息' : '上传发票信息'}}
                  </el-button> -->
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
        <el-card shadow="never" v-if="orderInfo.invoice_source == 0">
          <div v-if="orderInfo.invoice && orderInfo.invoice.receiveType == 'paper_invoice'">
            <el-row v-if="orderInfo.invoice.userName">
              <el-col :span="3" class="content-right"><h4>收件人：</h4></el-col>
              <el-col :span="10">
                {{ orderInfo.invoice.userName }}
              </el-col>
            </el-row>
            <el-row v-if="orderInfo.invoice.phoneNumber">
              <el-col :span="3" class="content-right"><h4>收件人手机号：</h4></el-col>
              <el-col :span="10">
                {{ orderInfo.invoice.phoneNumber }}
              </el-col>
            </el-row>
            <el-row v-if="orderInfo.invoice.cityName">
              <el-col :span="3" class="content-right"><h4>收件人地址：</h4></el-col>
              <el-col :span="10">
                {{ orderInfo.invoice.provinceName }}
                {{ orderInfo.invoice.cityName}}
                {{ orderInfo.invoice.countyName}}
                {{ orderInfo.invoice.detailInfo}}
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="3" class="content-right"><h4>电子邮箱：</h4></el-col>
              <el-col :span="5">{{ orderInfo.invoice && orderInfo.invoice.email }}</el-col>
            </el-row>
          </div>
        </el-card>
      </el-card>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <h3>商品清单</h3>
        </div>
        <div v-if="orderInfo.order_type == 'service' || orderInfo.order_type == 'bargain'">
          <el-row>
            <el-col :span="3" class="content-right">商品名称：</el-col>
            <el-col :span="5">{{ orderInfo.title }}</el-col>
            <el-col :span="3" class="content-right">商品数量：</el-col>
            <el-col :span="5">{{ orderInfo.item_num }}</el-col>
          </el-row>
        </div>
        <div v-show="orderInfo.order_type == 'normal'">
          <el-table :data="orderInfo.items" style="width: 100%">
            <el-table-column class="goods-img" prop="pic" label="商品图片" width="120">
              <template slot-scope="scope">
                <span><img class="item-image" :src="wximageurl + scope.row.pic" :alt="scope.row.item_name"/></span>
              </template>
            </el-table-column>
            <el-table-column prop="item_name" label="商品名称" width="180">
              <template slot-scope="scope">
                <el-badge :value="scope.row.badge" class="badge" type="info">
                  {{ scope.row.item_name }}&nbsp;&nbsp;
                </el-badge>
                <div><el-tag size="mini" type="success" v-if="orderInfo.edu_order_no != ''">教育优惠</el-tag></div>
                <div><el-tag size="mini" type="success" v-if="scope.row.order_item_type == 'gift'">赠品</el-tag></div>
              </template>
            </el-table-column>
            <el-table-column prop="item_spec_desc" label="规格" width="180">
              <template slot-scope="scope">
                {{ scope.row.item_spec_desc ? scope.row.item_spec_desc : '单规格' }}
              </template>
            </el-table-column>
            <el-table-column prop="price" label="单价" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.fee_symbol }}{{ scope.row.price / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="orderInfo.type=='1'" prop="price" label="计税单价" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.fee_symbol }}{{ scope.row.taxable_fee / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量" width="180"></el-table-column>
            <el-table-column label="总原价(元)">
              <template slot-scope="scope">
                <span>{{ scope.row.fee_symbol }}{{ scope.row.item_fee / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总支付价(元)">
              <template slot-scope="scope">
                <span>{{ scope.row.fee_symbol }}{{ scope.row.total_fee / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总优惠(元)">
              <template slot-scope="scope">
                <span>{{ scope.row.fee_symbol }}{{ scope.row.discount_fee / 100 }}</span>
              </template>
            </el-table-column>

            <template v-if="orderInfo.delivery_status == 'DONE' && orderInfo.delivery_corp">
              <el-table-column label="发货状态">
                <template slot-scope="scope">
                  <span>已发货</span>
                </template>
              </el-table-column>
              <el-table-column label="快递公司">
                <template slot-scope="scope">
                  <span v-if="orderInfo.order_status == 'WAIT_BUYER_CONFIRM' && false">
                    <el-select v-model="scope.row.delivery_corp" filterable placeholder="请选择快递公司，可搜索">
                      <el-option
                        v-for="item in dlycorps"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </span>
                  <span v-else>{{ scope.row.delivery_corp_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="快递单号">
                <template slot-scope="scope">
                  <span v-if="orderInfo.order_status == 'WAIT_BUYER_CONFIRM' && false">
                    <el-input v-model="scope.row.delivery_code" :maxlength=20 placeholder="物流公司单号"></el-input>
                  </span>
                  <span v-else>{{ scope.row.delivery_code }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </el-card>

      <el-card shadow="never" v-if="orderInfo.receipt_type == 'ziti'">
        <div slot="header" class="clearfix">
          <h3>自提信息</h3>
        </div>
        <el-row>
          <el-col :span="3" class="content-right"><h4>配送方式：</h4></el-col>
          <el-col :span="12" v-if="orderInfo.receipt_type == 'ziti'">
            到店自提
          </el-col>
        </el-row>
        <el-row v-if="orderInfo.receiver_name">
          <el-col :span="3" class="content-right"><h4>提货人：</h4></el-col>
          <el-col :span="12">{{ orderInfo.receiver_name }}</el-col>
          <PiiInfo pageType='1' memberType='receiver_name' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
        </el-row>
        <el-row v-if="orderInfo.receiver_mobile">
          <el-col :span="3" class="content-right"><h4>联系方式：</h4></el-col>
          <el-col :span="12">{{ orderInfo.receiver_mobile }}</el-col>
          <PiiInfo pageType='1' memberType='receiver_mobile' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
        </el-row>
<!--        <el-row v-if="orderInfo.receiver_address">
          <el-col :span="3" class="content-right"><h4>收货地址：</h4></el-col>
          <el-col :span="12">{{ orderInfo.receiver_state }}{{ orderInfo.receiver_city }}{{ orderInfo.receiver_district }}{{ orderInfo.receiver_address }}</el-col>
        </el-row>-->
      </el-card>
      <el-card shadow="never" v-else>
        <div slot="header" class="clearfix">
          <h3>配送信息</h3>
        </div>
        <el-row>
          <el-col :span="3" class="content-right"><h4>配送方式：</h4></el-col>
          <el-col :span="12" v-if="orderInfo.receipt_type == 'logistics'">
            物流配送
          </el-col>
        </el-row>
        <el-row v-if="orderInfo.receiver_name">
          <el-col :span="3" class="content-right"><h4>收货人：</h4></el-col>
          <el-col :span="12">
            {{ orderInfo.receiver_name }}
            <PiiInfo pageType='1' memberType='receiver_name' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
          </el-col>
        </el-row>
        <el-row v-if="orderInfo.receiver_mobile">
          <el-col :span="3" class="content-right"><h4>联系方式：</h4></el-col>
          <el-col :span="12">
            {{ orderInfo.receiver_mobile }}
            <PiiInfo pageType='1' memberType='receiver_mobile' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
          </el-col>
        </el-row>
        <el-row v-if="orderInfo.receiver_address">
          <el-col :span="3" class="content-right"><h4>收货地址：</h4></el-col>
          <el-col :span="12">{{ orderInfo.receiver_state }}{{ orderInfo.receiver_city }}{{ orderInfo.receiver_district }}{{ orderInfo.receiver_address }}
          <PiiInfo pageType='4' memberType='receiver_address' optionScene="order_detail"  :associatedId='orderInfo.order_id'/>
          </el-col>
        </el-row>
      </el-card>

      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <h3>店铺信息</h3>
        </div>
        <el-row v-if="orderInfo.receipt_type">
           <!-- === 'ziti' -->
          <el-col :span="3" class="content-right"><h4>店铺名称：</h4></el-col>
          <el-col :span="12">{{ distributor.store_name }}</el-col>
        </el-row>

        <el-row v-if="orderInfo.receipt_type">
           <!-- === 'ziti' -->
          <el-col :span="3" class="content-right"><h4>AppleID：</h4></el-col>
          <el-col :span="12">{{ distributor.shop_code }}</el-col>
        </el-row>

        <el-row v-if="orderInfo.receipt_type">
          <!--  === 'ziti'  -->
          <el-col :span="3" class="content-right"><h4>店铺地址：</h4></el-col>
          <el-col :span="12">{{ distributor.store_address }}</el-col>
        </el-row>

      </el-card>

      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <h3>门店购买商品清单</h3>
        </div>
        <div>
          <el-table :data="orderInfo.items_scan" style="width: 100%">
            <el-table-column prop="itemName" label="商品名称" width="180"></el-table-column>
            <el-table-column prop="num" label="数量" width="180"></el-table-column>
          </el-table>
        </div>
      </el-card>


      <el-dialog title="物流详情" class="right-dialog" :visible.sync="dialog" :before-close="handleCancelLabelsDialog">
        <div class="block" v-if="this.activities.length > 0">
          <div class="radio">
            排序：
            <el-radio-group v-model="reverse">
              <el-radio :label="true">正序</el-radio>
              <el-radio :label="false">倒序</el-radio>
            </el-radio-group>
          </div>
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.AcceptTime">
              {{ activity.AcceptStation }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div v-else class="block">暂无信息</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog = false">关  闭</el-button>
        </span>
      </el-dialog>

      <el-dialog title="订单开票" class="order-invoice-dialog" :visible.sync="orderInvoiceVisible" :before-close="onOrderInvoiceCloseChange">
        <el-row>
          <el-col :span="3" :offset="2">
            上传发票
          </el-col>
          <el-col :span="13">
            <el-upload
              :multiple="true"
              accept="image/jpeg,image/png,application/pdf"
              action=''
              :on-change="uploadInvoiceChange"
              :auto-upload="false"
              class="cus-upload"
              :on-remove="uploadInvoiceRemoveChange"
            >
              <el-button type="primary" size="medium">上传发票信息</el-button>
              <div slot="tip" class="el-upload__tip">仅支持jpg/png/pdf文件</div>
            </el-upload>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderInvoiceVisible = false">取 消</el-button>
          <el-button type="primary"  @click="onOrderInvoiceSumbitChange">提交</el-button>
        </span>
      </el-dialog>


<!--      <el-card shadow="never" v-if="(orderInfo.receipt_type=='logistics' || orderInfo.order_type == 'bargain')&&(orderInfo.delivery_type=='new')">-->
      <el-card shadow="never" v-if="(orderInfo.receipt_type==='logistics' || orderInfo.order_type === 'bargain')">
        <div slot="header" class="clearfix">
          <h3>包裹信息</h3>
        </div>

        <el-table :data="orderInfo.sf_response">
<!--          <el-table-column prop="customer_order_code" min-width="150" label="客户单号" align="center" show-overflow-tooltip></el-table-column>-->
<!--          <el-table-column prop="sf_order_id"  min-width="100" label="顺丰单号" align="center" show-overflow-tooltip></el-table-column>-->

          <el-table-column prop="delivery_corp_name" label="快递类型" align="center" show-overflow-tooltip></el-table-column>
<!--          <el-table-column prop="delivery_corp" label="物流编码" align="center" show-overflow-tooltip></el-table-column>-->
          <el-table-column prop="delivery_code" min-width="100" label="物流单号" align="center" show-overflow-tooltip>

            <template slot-scope="scope">
              <el-popover placement="left" width="400" @show="getDeliveryInfo(scope.row)" trigger="click" title="物流轨迹详情" :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }">
                <br>
                <el-timeline v-loading="deliveryLoading" style="max-height: 300px; overflow: auto">
                  <el-timeline-item
                    v-for="(activity, index) in deliveryInfo"
                    :key="index"
                    placement="top"
                    :timestamp="activity.AcceptStatus">
                    <el-card>
                      <h4>{{ activity.AcceptStation }}</h4>
                      <h6 v-if="activity.OpCode">操作码：{{ activity.OpCode }}</h6>
                      <h6 v-if="activity.ReasonCode">原因码：{{ activity.ReasonCode }}</h6>
                      <br>
                      <span>{{ activity.AcceptTime }}</span>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>

                <el-tag size="mini" slot="reference">{{ scope.row.delivery_code }}</el-tag>
              </el-popover>
            </template>

          </el-table-column>
          <el-table-column prop="delivery_time" min-width="100" label="发货时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="delivery_distance_meter" min-width="100" label="距离(米)" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time_call_to_delivery" min-width="100" label="发货时长(分钟)" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time_delivery_to_done" min-width="100" label="签收时长(分钟)" align="center" show-overflow-tooltip></el-table-column>

        </el-table>
      </el-card>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <h3>订单备注</h3>
        </div>
        <div>
          <h4>{{ orderInfo.remark }}</h4>
        </div>
      </el-card>
      <div class="section-footer content-padded content-center with-border">
        <el-button @click="retrunClick">返回</el-button>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss" type="text/css">
h3.title {
  padding: 20px;
  font-size: 18px;
  color: #ff5000;
}

.col-3 {
  width: 120px;
  margin-right: 10px;
}

.detail-info {
  margin: 0 10px;
}

.el-row {
  margin-bottom: 10px;
}

.item-image {
  max-width: 80px;
  margin: 0 auto;
}
.badge{
  margin-top: 10px;
  margin-right: 40px;
}
img {
  max-width: 100px;
  margin: 0 auto;
}

.block {
  margin-left: 85px;
}

.radio {
  margin-bottom: 15px;
}
.content-row {
  margin-top: 7px;
}
.upload-btn {
  margin-top: 15px;
}
.invoice-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #409EFF;
  border: 1px solid rgba(235, 238, 245, 1);
  border-top: none;
  padding: 5px 10px;
  border-left: none;
  border-right: none;
  .deletd-btn {
    color: #f56c6c;
    padding: 0px;
    cursor: pointer;
  }
  .el-icon-document {
    margin-right: 5px;
    color: #999;
  }
  .invoice-file-name {
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
.cus-upload {
  width: 100%;
  .tips {
    width: 100%;
  }
}
</style>
<script>
import {getOrderDetail, updateDelivery, processDrugOrders, getDeliveryDetail, getLogisticsList, getDeliveryLists, receivedOrder, updateOrderInvoice, uploadOrderInvoice, getOrderInvoiceList } from '@/api/trade'
import {getMember} from '@/api/member'
import {getWxShopsList} from '@/api/shop'
import hqbdlycorp_kname from '../../../../common/hqbdlycorp_kname.json'
import { mapGetters } from "vuex";
import PiiInfo from '@/components/piiInfo/index'


export default {
  props:{
    setOrder:{
      type: String,
      default: ''
    }
  },
  components: {
    PiiInfo
  },
  data() {
    return {
      dialog: false,
      shopsList: [],
      dialogVisible: false,
      loading: false,
      order_id: '',
      orderInfo: {},
      distributor: {},
      tradeInfo: {},
      tradeInfoTradein: {},
      tcb_order: {},
      tcb_order_aftersale: {},

      cancelData: {},
      profit: {},
      resource: '',
      user_id: 0,
      memberInfo: {
        gradeInfo: {},
      },
      form: {
        status: 'true',
        receipt_type: 'ziti',
        reject_reason: '',
      },
      reverse: true,
      activities: [],
      dlycorpskn: hqbdlycorp_kname,
      bigImageUrl: '',
      dlycorps: [],
      deliveryForm: {
        delivery_type: 'batch',
        order_id: '',
        delivery_corp: '',
        delivery_code: '',
        sepInfo: {},
      },
      deliveryData: {},
      params: {
        is_view: false,
      },
      deliveryInfo: [],
      deliveryLoading:true,
      uploadList: [],
      orderInvoiceVisible: false
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type'])
  },
  methods: {
    getDeliveryInfo(row) {
      this.deliveryInfo = [];
      this.deliveryLoading = true;
      let query = {
        delivery_corp: row.delivery_corp,
        delivery_code: row.delivery_code,
        order_id:this.orderInfo.order_id,
      }
      getDeliveryDetail(query).then((response) => {
        this.deliveryInfo = response.data.data;
        console.log(this.deliveryInfo);
        this.deliveryLoading = false;
      });
    },

    receivedOrder(order_id) {
      receivedOrder(order_id, {
        'receive_status': 1
      }).then((response) => {
        let res = response.data.data, msg = '操作失败！', type = 'error';
        console.log(res)
        if (res) {
          type = 'success';
          msg = '接单成功，请准备发货';
        }
        this.$message({
          type: type,
          message: msg,
        });
        this.getDetail();
      });
    },
    retrunClick() {
      this.$router.go(-1)
    },
    handleCancelLabelsDialog() {
      this.dialog = false
    },
    deliveryDesc() {
      this.dialog = true
      let query = {
        delivery_corp: this.orderInfo.delivery_corp,
        delivery_code: this.orderInfo.delivery_code,
        delivery_corp_source: this.orderInfo.delivery_corp_source,
      }
      getDeliveryDetail(query).then((response) => {
        this.activities = response.data.data
      })
    },
    getMemberInfo(filter) {
      getMember(filter).then((response) => {
        this.memberInfo = response.data.data
      })
    },
    process() {
      processDrugOrders(this.order_id, this.form).then((res) => {
        this.$message({type: 'success', message: '审核成功'})
        this.getDetail()
      })
    },
    remoteMethod(query) {
      getWxShopsList({page: 1, pageSize: 100, name: query, distributor_id: this.orderInfo.distributor_id}).then((res) => {
        this.shopsList = res.data.data.list
      })
    },
    getDetail() {
      this.loading = true
      getOrderDetail(this.order_id, this.params).then((response) => {
        this.orderInfo = response.data.data.orderInfo
        this.tradeInfo = response.data.data.tradeInfo
        this.tradeInfoTradein = response.data.data.tradeInfoTradein || []
        this.tcb_order = response.data.data.tcb_order || []
        this.tcb_order_aftersale = response.data.data.tcb_order_aftersale || []

        this.distributor = response.data.data.distributor
        this.cancelData = response.data.data.cancelData
        this.profit = response.data.data.profit
        this.loading = false
        // this.getMemberInfo({user_id: this.orderInfo.user_id})
        if (response.data.data.orderInfo.pack) {
          this.orderInfo.pack = JSON.parse(response.data.data.orderInfo.pack)
        }
        if (this.orderInfo.order_status_msg === '待支付'){
          this.orderInfo.order_status_msg = '待付款';
        }
      });
      /*getDeliveryLists({order_id: this.order_id}).then((response) => {
        this.deliveryData = response.data.data
      })*/
    },
    imagedetail(imgurl) {
      this.dialogVisible = true
      this.bigImageUrl = imgurl
    },
    getLogisticsList() {
      getLogisticsList().then((res) => {
        this.dlycorps = res.data.data.list
      })
    },
    updateDeliveryAction() {
      // 修改物流信息
      if (this.orderInfo.delivery_corp) {
        this.deliveryForm.delivery_corp = this.orderInfo.delivery_corp
        this.deliveryForm.delivery_code = this.orderInfo.delivery_code
        this.deliveryForm.sepInfo = {}
      } else {
        this.deliveryForm.delivery_type = 'sep'
        this.deliveryForm.sepInfo = JSON.stringify(this.orderInfo.items)
        this.deliveryForm.delivery_corp = ''
        this.deliveryForm.delivery_code = ''
      }
      updateDelivery(this.order_id, this.deliveryForm).then((response) => {
        var deliveryStatus = response.data.data.delivery_status
        if (deliveryStatus && deliveryStatus != 'PENDING') {
          this.$message.success('修改物流信息成功!')
          this.getDetail()
        } else {
          this.$message.error('修改物流信息失败!')
          return false
        }
      })
    },
    deleteInvoiceChange (item) {
      this.$confirm('当前操作将删除该发票信息，确认继续吗？', '删除发票', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() =>{
        let listArr = this.orderInfo.invoice_files && this.orderInfo.invoice_files.filter(el => el.id != item.id)
        let params = {
          order_id: this.order_id,
          images: listArr
        }
        updateOrderInvoice(params).then(response => {
          this.$message({ type: 'success', message: '删除成功'})
          this.getDetail()
          this.onOrderInvoiceCloseChange()
        })
      }).catch(err => {
        console.log(err)
      })
    },
    updateInvoiceBtnChange () {
      this.orderInvoiceVisible = true
      this.uploadList = []
    },
    uploadInvoiceChange(file, fileList) {
      let params = { file: file.raw, isUploadFile: true }
      uploadOrderInvoice(params).then(response => {
        const data = response.data.data
        if (data) this.uploadList.push({...data, name: data.file_name})
      }).catch(err => {
        console.log(err)
        this.uploadList = []
      })
    },
    uploadInvoiceRemoveChange (file, fileList) {
      const obj = this.uploadList.find(el => el.uid == file.uid)
      this.uploadList.splice(this.uploadList.indexOf(obj), 1)
    },
    onOrderInvoiceCloseChange() {
      this.orderInvoiceVisible = false
      this.uploadList = []
    },
    onOrderInvoiceSumbitChange () {
      if (this.uploadList.length <= 0) {
        this.$message({ type: 'error', message: '请选择上传文件'})
        return
      }
      const files = this.orderInfo.invoice_files || []
      const params = {
        order_id: this.order_id,
        images: [...files, ...this.uploadList]
      }
      updateOrderInvoice(params).then(response => {
        this.$message({ type: 'success', message: '更新成功'})
        this.getDetail()
        this.onOrderInvoiceCloseChange()
      })
    },
    async showInvoiceChange (item) {
      const res = await getOrderInvoiceList({ order_id: this.order_id, id: item.id })
      window.open(res.data.data[0].url, "_blank");
    },
  },
  mounted() {
    if(this.order_id === ''){
      this.order_id = this.setOrder
    }
    if (this.$route.query.orderId) {
      this.order_id = this.$route.query.orderId
    }
    if (this.$route.query.resource) {
      this.resource = this.$route.query.resource
    }
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id
    }
    if (this.$route.query.is_view) {
      this.params.is_view = this.$route.query.is_view;
    }
    this.getDetail()
    this.getLogisticsList()
  },
  watch: {
    setOrder(newVal, oldVal) {
      this.order_id = newVal
      this.getDetail()
      this.getLogisticsList()
    }
  }
}
</script>
