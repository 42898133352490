<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>物料信息设置</span>
      </div>

      <el-form ref="formRules" :model="formParams" label-width="130px">
        <el-form-item label="物料名称" :rules="formRules['material_name']" prop="material_name">
          <el-input type="text" v-model="formParams.material_name"></el-input>
        </el-form-item>
        <el-form-item label="物料失效时间">
          <el-date-picker v-model="formParams.expire_time" type="datetime" value-format="timestamp" placeholder="选择日期时间"
            default-time="23:59:59">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="物料类型">
          <el-radio-group v-model="formParams.material_type" @input="changeMaterialType">
            <el-radio-button label="page">按页面</el-radio-button>
            <el-radio-button label="lottery">按抽奖活动</el-radio-button>
            <el-radio-button label="offline_activity">按门店活动</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="设置生成页面" v-if="formParams.material_type == 'page'">
          <el-radio-group v-model="formParams.page_type" @input="changePathtype">
            <el-radio label="custom_page">自定义</el-radio>
            <el-radio label="card">领券页</el-radio>
            <el-radio label="input">手动填写页面路径及参数</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="小程序"
          v-if="formParams.material_type == 'page' || formParams.material_type == 'offline_activity'">
          <el-radio-group v-model="formParams.program">
            <el-radio label="wechat_zyd"
              v-if="formParams.page_type != 'card' && formParams.material_type != 'offline_activity'">微信专营店</el-radio>
            <el-radio label="wechat_order">微信专营店商城</el-radio>
            <el-radio label="bytemini_zyd">抖音专营店</el-radio>
          </el-radio-group>
          <div style="color: #F2630A">微信专营店和抖音专营店，不支持为销售渠道</div>
        </el-form-item>

        <!-- 按页面 -->
        <div v-if="formParams.material_type == 'page'">
          <!-- 自定义页面 -->
          <el-form-item label="自定义页面" v-if="formParams.page_type == 'custom_page'">
            <el-table :data="tableData" class="custom-item">
              <el-table-column prop="id" label="页面ID">
              </el-table-column>
              <el-table-column prop="page_name" label="页面名称">
              </el-table-column>
            </el-table>
            <el-button type="primary" plain @click="addCustomizeAction">选择自定义页面</el-button>
          </el-form-item>
          <!-- 优惠券 -->
          <el-form-item label="优惠券" v-if="formParams.page_type == 'card'">
            <el-table :data="editorData" class="custom-item">
              <el-table-column prop="card_id" label="券ID">
              </el-table-column>
              <el-table-column prop="title" label="券名称">
              </el-table-column>
              <el-table-column prop="name" label="券有效期" width="230">
                <template slot-scope="scope">
                  <div v-if="scope.row.takeEffect">
                    {{ scope.row.takeEffect }}
                    <div v-if="scope.row.end_date > 0">
                      统一过期时间：{{ scope.row.end_date | datetime("yyyy-MM-dd") }}
                    </div>
                  </div>
                  <div v-else>
                    {{ scope.row.begin_time | datetime("yyyy-MM-dd") }}
                    <template v-if="scope.row.end_time">~</template>
                    {{ scope.row.end_time | datetime("yyyy-MM-dd") }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" plain @click="showCoupons">选择优惠券</el-button>
          </el-form-item>

          <!-- 手动填写 -->
          <div v-if="formParams.page_type == 'input'">
            <el-form-item v-for="(page_path, index) in formParams.page_path" :label="index == 0 ? '页面地址及参数' : ''"
              :key="index + 'value'" :prop="'page_path.' + index + '.value'" :rules="formRules['page_path']">
              <div class="url-list">
                <el-input placeholder="请输入内容" v-model="page_path.value">
                  <template slot="prepend">链接{{ index + 1 }}</template>
                </el-input>
                <el-button v-if="index > 0" slot="append" type="text" @click="deleteUrlli(index)">删除</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain @click="addUrl">添加链接</el-button>
            </el-form-item>
          </div>
        </div>

        <!-- 按抽奖活动 -->
        <el-form-item label="抽奖活动" v-if="formParams.material_type == 'lottery'">
          <el-table :data="lotteryTableData" class="custom-item">
            <el-table-column prop="marketing_id" width="80" label="活动ID"></el-table-column>
            <el-table-column prop="marketing_title" min-width="110" label="活动标题" />
            <el-table-column prop="marketing_type" min-width="110" label="活动类型">
              <template slot-scope="scope">
                <span>{{ scope.row.marketing_type == 'normal' ? '常规礼品' : '限定礼品' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="overdue_time" min-width="180" label="有效时间">
              <template slot-scope="scope">
                {{ scope.row.start_time | datetime('yyyy-MM-dd hh:mm:ss') }}
                ~
                {{ scope.row.end_time | datetime('yyyy-MM-ddhh: mm: ss') }}
              </template>
            </el-table-column>
          </el-table>
          <el-button type="primary" plain @click="addLottery">选择抽奖活动</el-button>
        </el-form-item>

        <!-- 按门店活动 -->
        <div v-if="formParams.material_type == 'offline_activity'">
          <el-form-item label="门店活动">
            <el-table :data="offlineTableData" class="custom-item">
              <el-table-column prop="activity_id" width="60" label="编号"></el-table-column>
              <el-table-column prop="activity_title" label="活动标题"></el-table-column>
              <el-table-column prop="total_fee" label="活动类型">
                <template slot-scope="scope">
                  <div>
                    <el-tag v-if="scope.row.join_type === 'appointment'" type="primary" size="mini">预约报名</el-tag>
                    <el-tag v-else type="success" size="mini">现场报名</el-tag>
                  </div>
                  <div>
                    <el-tag v-if="scope.row.sign_in_system === 'local'" size="mini" type="info">数字签到码</el-tag>
                    <el-tag v-else-if="scope.row.sign_in_system === 'guestbook'" size="mini"
                      type="info">GuestBook</el-tag>
                    <el-tag v-else-if="scope.row.sign_in_system === 'wecom'" size="mini" type="info">Wecom</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="total_fee" label="报名时间">
                <template slot-scope="scope">
                  <div>
                    <template>
                      {{ scope.row.sign_up_start_time | datetime("yyyy-MM-dd hh:mm:ss") }} - <br>
                      {{ scope.row.sign_up_end_time | datetime("yyyy-MM-dd hh:mm:ss") }}
                      <div style="color: red" v-if="scope.row.is_end == 1">已结束</div>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" plain @click="addOffline">选择门店活动</el-button>
          </el-form-item>

          <el-form-item label="活动落地页">
            <el-radio-group v-model="formParams.page_type">
              <el-radio label="activity_detail">活动详情页</el-radio>
              <el-radio label="activity_transfer">活动中转页</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <!-- 选择门店 -->
        <el-form-item label="选择门店"
          :rules="((formParams.material_type == 'lottery' || formParams.material_type == 'offline_activity') && formParams.distributor_type == 'distributor') ? formRules['distributor_temp_id'] : {}"
          prop="distributor_temp_id" v-if="(formParams.material_type == 'page' && formParams.page_type == 'custom_page') ||
        formParams.material_type == 'lottery' ||
        formParams.material_type == 'offline_activity'">
          <el-radio-group v-model="formParams.distributor_type"
            v-if="formParams.material_type == 'lottery' || formParams.material_type == 'offline_activity'">
            <el-radio label="activity">按活动适用门店</el-radio>
            <el-radio label="distributor">按指定门店</el-radio>
          </el-radio-group>
          <div v-if="isShopUpload()">
            <div class="shop-list">
              <div>已添加{{ shopTotal }}家门店</div>
              <div>
                <el-button type="text" @click="handleShopDialog">查看</el-button>
                <el-button type="text" @click="handleEliminate">清除</el-button>
              </div>
            </div>
            <div class="add-channel">
              <!-- <el-button type="primary" plain @click="handleCloseDialogAction">添加门店</el-button> -->
              <Upload :tempId="shopFormValue.temp_id" @shopUpload='shopUpload' category="shop" btnText="导入门店" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>渠道参数设置</span>
      </div>
      <el-form ref="form" :model="formParams" label-width="110px">
        <el-form-item label="渠道及参数">
          <div class="shop-list">
            <div>已添加{{ utmTotal }}个渠道</div>
            <div>
              <el-button type="text" @click="handleTtmDialog">查看</el-button>
              <el-button type="text" @click="handleChannelLIst">清除</el-button>
            </div>
          </div>

          <div class="add-channel">
            <el-button type="primary" plain @click="addUtmParams">添加渠道</el-button>
            <Upload :tempId="utmTempId" category="utm" @utmUpload='utmUpload' btnText="导入渠道" />
          </div>
          <div>请确保小程序已有对应参数解析逻辑</div>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 查看门店 -->
    <el-dialog title="查看门店" :visible.sync="checkShopDialog" width="70%">
      <el-table :data="shopList" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="distributor_id" label="ID" width="90"></el-table-column>
        <el-table-column prop="shop_code" label="门店AppleId" width="120"></el-table-column>
        <el-table-column prop="distributor_name" label="店铺名称"></el-table-column>
      </el-table>

      <el-pagination class="utm-pagination" size-change="utmHandleSizeChange" @current-change="shopHandleCurrentChange"
        :current-page.sync="shopFormValue.page" :page-size="shopFormValue.pageSize" layout="total, prev, pager, next"
        :total="shopTotal">
      </el-pagination>
    </el-dialog>

    <!-- 查看渠道参数 -->
    <el-dialog title="查看渠道参数" :visible.sync="checkUtmDialog" width="70%">
      <el-row>
        <el-input class="input-b" placeholder="渠道名称" v-model="checkUtmParams.channel_name"></el-input>
        <el-input class="input-b" placeholder="utm_source" v-model="checkUtmParams.utm_source"></el-input>
        <el-button type="primary" icon="plus" @click="searchUtmList">搜索</el-button>
        <el-button type="primary" icon="plus" @click="deleteUtmItem">批量删除</el-button>
      </el-row>
      <el-table ref="multipleTable" :data="utmTableData" tooltip-effect="dark" style="width: 100%" row-key="id"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
        <el-table-column label="渠道ID" prop="id" width="100">
        </el-table-column>
        <el-table-column label="渠道名称" prop="channel_name">
        </el-table-column>
        <el-table-column label="utm_source" prop="utm_source">
        </el-table-column>
        <el-table-column label="utm_medium" prop="utm_medium">
        </el-table-column>
        <el-table-column label="utm_campaign" prop="utm_campaign">
        </el-table-column>
        <el-table-column label="utm_content" prop="utm_content">
        </el-table-column>
        <el-table-column label="utm_term" prop="utm_term">
        </el-table-column>
        <el-table-column label="是否为销售渠道">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_sales_channel" active-value="1" inactive-value="0"
              @change='utmSalesChannelChange(scope.row)'></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editUtmli(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteUtmli(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="utm-pagination" size-change="utmHandleSizeChange" @current-change="utmHandleCurrentChange"
        :current-page.sync="checkUtmParams.page" :page-size="checkUtmParams.pageSize" layout="total, prev, pager, next"
        :total="utm_total">
      </el-pagination>

    </el-dialog>
    <!-- 添加渠道参数 -->
    <el-dialog title="设置渠道参数" :visible.sync="addTtmDialog" width="50%">
      <el-form class="utm-content" :model="utmKey" :rules="utmRules" ref="utmRuleForm" label-width="110px">
        <el-form-item label="是否为销售渠道">
          <el-switch v-model="utmKey.is_sales_channel" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="渠道名" prop="channel_name">
          <el-input placeholder="请输入投放渠道名称" v-model="utmKey.channel_name"></el-input>
        </el-form-item>
        <el-form-item label="渠道参数" prop="utm_source">
          <el-input placeholder="输入内容" v-model="utmKey.utm_source">
            <template slot="prepend">utm_source</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="utm_medium">
          <el-input placeholder="输入内容" v-model="utmKey.utm_medium">
            <template slot="prepend">utm_medium</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="utm_campaign">
          <el-input placeholder="输入内容" v-model="utmKey.utm_campaign">
            <template slot="prepend">utm_campaign</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="utm_content">
          <el-input placeholder="输入内容" v-model="utmKey.utm_content">
            <template slot="prepend">utm_content</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="utm_term">
          <el-input placeholder="输入内容" v-model="utmKey.utm_term">
            <template slot="prepend">utm_term</template>
          </el-input>
          <span>如果生成物料包含了门店ID,将会在utm_term设置的参数值后追加拼接门店Apple ID</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTtmDialog = false">取 消</el-button>
        <el-button type="primary" @click="addUtmItem">确 定</el-button>
      </span>
    </el-dialog>

    <div class="submit-btn">
      <el-button type="primary" :disabled="isSubmit" @click="handleSkuSubmit">生成物料</el-button>
    </div>

    <!-- 选择自定义页面 -->
    <CustomizeSelect :store-visible="CustomizeVisible" :is-valid="isValid" :rel-data-ids="customizeList"
      :get-status="CustomizeStatus" :plat-form="formParams.program == 'bytemini_zyd' ? 'byte_mini' : 'order'"
      @chooseStore="CustomizeChooseAction" @closeStoreDialog="addCustomizeAction">
    </CustomizeSelect>

    <!-- 选择门店 -->
    <DistributorSelect :store-visible="DistributorVisible" :rel-data-ids="relDistributors"
      :get-status="DistributorStatus" @chooseStore="DistributorChooseAction"
      @closeStoreDialog="handleCloseDialogAction"></DistributorSelect>

  </div>
</template>

<script>
import CustomizeSelect from '@/components/function/customizeSelect'
import DistributorSelect from '@/components/function/distributorSelect'
import Upload from './comps/upload.vue'
import { setMaterial, getTempChannelParams, setTempChannelParams, deleteTempChannelParams, getRelDistributorTempList } from '@/api/material'

export default {
  components: {
    CustomizeSelect,
    DistributorSelect,
    Upload
  },
  data() {
    return {
      rules: [],
      formParams: {
        material_type: 'page',
        material_name: '',
        expire_time: '',
        page_type: 'custom_page',
        program: 'wechat_zyd',
        page_path: [{ value: '' }],
        rel_id: '', // 关联自定义页/卡券/活动ID
        distributor_ids: '',
        distributor_type: 'activity',
        distributor_temp_id: ''
      },
      shopFormValue: {
        temp_id: '',
        page: 1,
        pageSize: 10
      },
      shopList: [],
      shopTotal: 0,
      tableData: [],
      CustomizeVisible: false,
      isValid: false,
      customizeList: [],
      CustomizeStatus: false,
      relDistributors: [],
      checkShopDialog: false,
      editorData: [],
      addTtmDialog: false,
      utmKey: {},
      utmIpt: {
        is_sales_channel: '1',
        channel_name: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: ''
      },
      checkUtmDialog: false,
      checkUtmParams: {
        channel_name: '',
        utm_source: '',
        page: 1,
        pageSize: 10
      },
      utmTableData: [],
      utmTotal: 0,
      utm_total: 0,
      lotteryTableData: [],
      offlineTableData: [],
      DistributorVisible: false,
      DistributorStatus: false,
      checkTtmList: [],
      selectRows: [],
      isSubmit: false,
      utmTempId: '',
      utmRules: {
        channel_name: [
          { required: true, message: '请输入渠道名称', trigger: 'blur' },
        ],
        utm_source: [
          { required: true, message: '请输入utm_source', trigger: 'blur' },
        ],
      },
      formRules: {
        material_name: [
          { required: true, message: '请输入物料名称', trigger: 'blur' },
        ],
        page_path: [
          { required: true, message: '请输入链接', trigger: 'blur' },
        ],
        distributor_temp_id: [
          { required: true, message: '请选择门店', trigger: 'blur' },
        ]
      }
    }
  },
  props: {
  },
  watch: {
  },
  methods: {
    changeMaterialType(v) {
      this.resetParams()
      if (v == 'offline_activity') {
        this.formParams.page_type = 'activity_detail'
        this.formParams.program = 'wechat_order'
      } else {
        this.formParams.page_type = 'custom_page'
      }
    },
    addUrl() {
      let arr = [...this.formParams.page_path]
      arr.push({ value: '' })
      this.formParams.page_path = arr
    },
    deleteUrlli(index) {
      let arr = [...this.formParams.page_path]
      arr.splice(index, 1)
      this.formParams.page_path = arr
    },
    changePathtype(val) {
      this.resetParams()
      if (val == 'card') {
        this.formParams.program = 'wechat_order'
      }
    },
    resetParams() {
      this.formParams.rel_id = ''
      this.formParams.distributor_ids = ''
      this.formParams.page_path = [{ value: '' }]

      this.tableData = [] //自定义页面选项
      this.editorData = [] //优惠券选项
      this.lotteryTableData = [] //抽奖活动选项
      this.offlineTableData = [] //门店活动选项
      this.relDistributors = [] //门店选项
    },
    // 自定义页面选择
    async addCustomizeAction() {
      this.CustomizeStatus = !this.CustomizeStatus
      this.CustomizeVisible = !this.CustomizeVisible
    },
    CustomizeChooseAction(data) {
      this.CustomizeVisible = false
      this.CustomizeStatus = false
      if (data === null || data.length <= 0) return
      this.tableData = data
      this.formParams.rel_id = data[0].id
    },
    handleShopDialog() {
      this.checkShopDialog = true
      this.relDistributorTempList()
    },
    relDistributorTempList() {
      const params = {
        ...this.shopFormValue,
      }
      if (!params.temp_id) return
      getRelDistributorTempList(params).then(res => {
        let data = res.data.data
        if (data.list) {
          this.shopList = data.list
          this.shopTotal = data.total_count
        }
      })
    },
    shopUpload(id) {
      this.shopFormValue.temp_id = id
      this.formParams.distributor_temp_id = id
      this.relDistributorTempList()
      this.$refs['formRules'].validateField('distributor_temp_id');
    },
    shopHandleCurrentChange(v) {
      this.shopFormValue.page = v
      this.relDistributorTempList()
    },
    // 优惠券选择器绑定事件
    async showCoupons() {
      const { data } = await this.$picker.coupon({
        data: this.formParams.rel_id,
        multiple: false,
        params: {
          receive: ['true', 'password'], // 自定义筛选领取方式
          card_type: this.formParams.program == 'bytemini_zyd' ? 'gift' : ''
        }
      })
      this.editorData = data
      this.formParams.rel_id = data[0].card_id
    },
    // 渠道参数
    addUtmParams() {
      this.addTtmDialog = !this.addTtmDialog
      if (this.addTtmDialog) {
        this.utmKey = { ...this.utmIpt }
        // this.$refs['utmRuleForm'].resetFields();
      }
    },
    handleTtmDialog() {
      this.checkUtmDialog = true
      this.checkTtmList = []
    },
    // 渠道信息列表
    tempChannelParams() {
      let params = {
        ...this.checkUtmParams,
        temp_id: this.utmTempId
      }
      getTempChannelParams(params).then(res => {
        let data = res.data.data
        if (data.list) {
          this.utmTableData = data.list
          this.utm_total = data.total_count
          if (!params.channel_name && !params.utm_source) {
            this.utmTotal = data.total_count
          }
          this.checkTtmList = []
          if (this.selectRows.length > 0) {
            this.$refs.multipleTable.clearSelection()
            this.selectRows.forEach(item => {
              this.$refs.multipleTable.toggleRowSelection(item)
            })
          }
        }
      })
    },
    utmUpload(id) {
      this.utmTempId = id
      this.tempChannelParams()
    },
    handleChannelLIst() {
      this.utmTotal = 0
      this.utmTempId = ''
      this.utmTableData = []
    },
    searchUtmList() {
      this.checkUtmParams.page = 1
      this.tempChannelParams()
    },
    deleteUtmItem() {
      // 批量删除应该优化
      let arr = []
      this.checkTtmList.forEach(item => {
        arr.push(item.id)
      })
      this.deleteUtmli({ id: arr.join(',') })
    },
    deleteUtmli(i) {
      let params = {
        temp_id: this.utmTempId,
        params_id: i.id
      }
      deleteTempChannelParams(params).then(res => {
        this.checkUtmParams.page = 1
        this.selectRows = []
        this.tempChannelParams()
      })
    },
    editUtmli(i) {
      this.utmKey = i
      this.addTtmDialog = true
    },
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.checkTtmList = value
        value.forEach(item => {
          let isInArr = this.selectRows.findIndex((n) => n.id == item.id)
          if (isInArr == -1) {
            this.selectRows.push(item)
          }
        })
      }
    },
    // 添加渠道
    addUtmItem(t) {
      this.$refs['utmRuleForm'].validate((valid) => {
        if (valid) {
          let params = { ...this.utmKey }
          if (this.utmTempId) {
            params.temp_id = this.utmTempId
          }
          if (params.id) {
            params.params_id = params.id // 编辑
          }
          setTempChannelParams(params).then(res => {
            let data = res.data.data
            if (data.temp_id) {
              this.utmTempId = data.temp_id
            }
            this.checkUtmParams.channel_name = ''
            this.checkUtmParams.utm_source = ''
            this.tempChannelParams()
          })
          if (t != 'noCloce') {
            this.addUtmParams()
          }
        }
      });
    },
    utmHandleSizeChange(v) {
      console.log("🚀 ~ utmHandleSizeChange ~ v:", v)
    },
    utmHandleCurrentChange(v) {
      this.checkUtmParams.page = v
      this.tempChannelParams()
    },
    utmSalesChannelChange(item) {
      this.utmKey = { ...item }
      this.addUtmItem('noCloce')
    },
    // 抽奖活动
    async addLottery() {
      const { data } = await this.$picker.lottery({
        data: this.formParams.rel_id,
        multiple: false
      })
      this.lotteryTableData = data
      this.formParams.rel_id = data[0].marketing_id

    },
    // 门店活动
    async addOffline() {
      const { data } = await this.$picker.offlineactivity({
        data: this.formParams.rel_id,
        multiple: false,
        params: {
          status: 'all',
          platform: this.formParams.program == 'bytemini_zyd' ? 'byte_mini' : 'wxapp'
        }
      })
      this.offlineTableData = data
      this.formParams.rel_id = data[0].activity_id
    },
    // 选择门店
    handleCloseDialogAction() {
      this.DistributorStatus = !this.DistributorStatus
      this.DistributorVisible = !this.DistributorVisible
    },
    DistributorChooseAction(data) {
      let arr = []
      data.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.handleCloseDialogAction()
      this.relDistributors = data
      this.formParams.distributor_ids = arr.join(',')
      this.$refs['formRules'].validateField('distributor_temp_id');
    },
    handleEliminate() {
      this.shopTotal = 0
      this.formParams.distributor_temp_id = ''
      this.shopList = []
      this.shopFormValue.temp_id = ''
      this.relDistributors = []
      this.formParams.distributor_ids = ''
    },
    isShopUpload() {
      let type = this.formParams.material_type
      if (type == 'page') {
        return true
      } else if (type == 'lottery' || type == 'offline_activity') {
        if (this.formParams.distributor_type == 'activity') {
          return false
        } else {
          return true
        }
      }
    },
    // 创建物料
    handleSkuSubmit() {
      this.$refs['formRules'].validate((valid) => {
        this.isSubmit = true
        if (valid) {
          let pagePath = []
          this.formParams.page_path.forEach(item => {
            if (item.value) {
              pagePath.push(item.value)
            }
          })
          let params = {
            ...this.formParams,
            channel_temp_id: this.utmTempId,
          }
          params.expire_time = params.expire_time / 1000
          params.page_path = pagePath.length > 0 ? JSON.stringify(pagePath) : ''
          setMaterial(params).then(res => {
            this.isSubmit = false
            this.$message({
              message: '创建成功',
              type: 'success'
            });
          }).catch(err => {
            this.isSubmit = false
          })
        } else {
          this.isSubmit = false
        }
      });
    },
  }
}

</script>

<style scoped lang="scss">
.el-form {
  .el-form-item {
    width: 800px;
  }
}

.mag {
  font-size: 12px;
  line-height: 20px;
  color: #c0c4cc;
  margin: 20px 0px;
}

.clearfix {
  height: 20px;
  line-height: 20px;
}

.detile-btn {
  padding: 0px;
  float: right;
}

.custom-item {
  margin-bottom: 20px;
}

.url-list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .el-input {
    width: 500px;
  }
}

.shop-list {
  width: 400px;
  height: 50px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.submit-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.utm-content {
  .el-form-item {
    width: 80%;
  }
}

.add-channel {
  display: flex;
}

.utm-pagination {
  text-align: center;
}
</style>
