<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col>
          <shop-select distributors  @update="storeSearch" :shopIdDefault="params.distributor_id" :distributor-valid="'true,false,delete'"></shop-select> <!--distributors wxshops 需要哪个api传哪个-->
          <el-input class="input-m" placeholder="店铺AppleId" v-model="params.shop_code">
            <el-button slot="append" icon="el-icon-search" @click="shopCodeSearch"></el-button>
          </el-input>

          <el-input class="input-m" placeholder="请输入商品名称" v-model="params.keywords">
            <el-button slot="append" icon="el-icon-search" @click="goodsNumberSearch"></el-button>
          </el-input>

          <el-input class="input-m" placeholder="请输入商品编号" v-model="params.keywords_bn">
            <el-button slot="append" icon="el-icon-search" @click="goodsNumberSearch"></el-button>
          </el-input>
          <el-select v-model="params.auto_receiving" clearable placeholder="支持自动接单" @change="goodsNumberSearch">
            <el-option label="是" size="mini" value="true"></el-option>
            <el-option label="否" size="mini" value="false"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row v-if="login_type == 'admin_del'  || login_type == 'staff' || login_type == 'disti' " :gutter="20" >
            <el-button type="primary"  icon="el-icon-box" plain style="margin-left: 10px;width: 150px; border-right-color:#b3d8ff;" @click="exportListDealer()">经销商导出</el-button>

            <el-button size="medium"  style="margin-left: 10px;" @click="openDialog">+ 选择经销商</el-button>
              <!--
              <span v-if="relDealers[0] != undefined" >
                <span class="" style="margin-left: 20px;" >{{relDealers[0].name}}</span>
              </span>
               -->
      </el-row>
      <el-row v-if="login_type == 'admin_del'  || login_type == 'staff'  || login_type == 'disti' " :gutter="20" >
              <el-tag :key="item.dealer_id" class="new-tag"  style="margin-left: 10px;" v-for="(item,index) in relDealers" closable :disable-transitions="false" @close="dealerhandleClose(index)"> {{item.name}}
              </el-tag>
      </el-row>


      <el-row v-if="login_type == 'dealer' " :gutter="20" >
            <el-button type="primary"  icon="el-icon-box" plain style="margin-left: 10px;width: 150px; border-right-color:#b3d8ff;" @click="exportListStore()">店铺导出</el-button>

              <el-button size="medium" class="button-new-tag"  style="margin-left: 10px;" @click="addDistributoreAction">+ 选择店铺 </el-button>

      </el-row>
      <el-row v-if="login_type == 'dealer' " :gutter="20" >
        <el-tag :key="item.distributor_id" class="new-tag"  style="margin-left: 10px;" v-for="(item,index) in relDistributors" closable :disable-transitions="false" @close="DistributoreHandleClose(index)"> {{item.name}}
        </el-tag>
      </el-row>

      <!-- v-if="this.operator_type == 'admin' || this.operator_type == 'staff'" -->
      <div v-if="login_type !== 'disti' && login_type !== 'admin' && login_type !== 'staff'">
        <ImportDialog type="primary" icon="el-icon-setting" style="margin-top: -5px" fileName="导入店铺商品（商品上下架&修改库存）"   buttonName="导入店铺商品（商品上下架 / 修改库存）" fileType="inventory"></ImportDialog>
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="OMS开启后不能修改库存">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
      </div>


      <!-- <ImportDialog v-if="login_type !== 'disti' && login_type !== 'admin' && login_type !== 'staff'" type="primary" icon="el-icon-upload2" style="margin-top: -5px" fileName="导入店铺商品上下架" buttonName="^ 批量上下架"   fileType="iscansale"></ImportDialog> -->

      <el-row :gutter="20" style="margin-top: 5px">
        <el-col>
          <el-button-group>
            <el-button v-if="login_type !== 'disti'
                            && login_type !== 'admin'
                            && login_type !== 'staff'"
                       type="success" @click="handleBatchOnline(true)" icon="el-icon-upload2" style="margin-left: 0px">批量上架</el-button>
            <el-button v-if="login_type !== 'disti'
                            && login_type !== 'admin'
                            && login_type !== 'staff'"
                       type="warning" @click="handleBatchOnline(false)" icon="el-icon-download" style="margin-left: 5px">批量下架</el-button>
            <el-button v-if="login_type !== 'admin'" type="info" icon='el-icon-picture-outline' @click="handleBatchDownload"  style="margin-left: 5px">商品码下载</el-button>
          </el-button-group>
<!--          <el-upload action="" style="display: inline-block; width: 100px;height:35px; " :on-change="uploadHandleChange" :auto-upload="false" :show-file-list="false" >-->
<!--            <el-button type="primary" icon="el-icon-setting" style="">批量修改库存</el-button>-->
<!--          </el-upload>-->
          <el-button v-if="login_type !== 'admin'" type="primary"  icon="el-icon-files" style="margin-left: 10px;" @click="exportList">单店导出</el-button>
        </el-col>
      </el-row>


    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.name" :name="item.activeName">
        <el-table :data="list" style="width: 100%" :height="wheight-240" v-loading="loading" ref="multipleItemsTable" @selection-change="handleItemsSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column v-if="login_type !== 'disti' && login_type !== 'admin' && login_type !== 'staff'" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleSkuEdit(scope.row)">设置SKU</el-button>
            </template>
          </el-table-column>
          <el-table-column v-if="login_type !== 'disti' && login_type !== 'admin' && login_type !== 'staff'" label="上下架操作">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.goods_can_sale"
                @change="switchStatusChange(scope.$index, scope.row, true) "
                active-color="#67C23A"
                inactive-color="#E6A23C"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="item_name" label="商品名称" min-width="300"></el-table-column>
          <el-table-column prop="auto_receiving" label="自动接单" min-width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.auto_receiving === '1'" type="success" size="mini">已开启</el-tag>
              <el-tag v-else type="danger" size="mini">已关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="item_bn" label="货号" min-width="200"></el-table-column>
          <!-- TODO 门店商品列表 -->
          <el-table-column prop="price" label="市场分类" min-width="100">
            <template slot-scope="scope">
              <el-button v-if="scope.row.type_warehouse == 2" size="mini" type="primary" plain>配件</el-button>
              <el-button v-if="scope.row.type_warehouse == 1" size="mini" type="warning" plain>非配件</el-button>
            </template>
          </el-table-column>

          <el-table-column prop="price" label="门店可售库存" min-width="100">
            <template slot-scope="scope">
              {{scope.row.cansale_store_total}}
            </template>
          </el-table-column>

          <el-table-column prop="price" label="仓库可售库存" min-width="100">
            <template slot-scope="scope">
              {{scope.row.cansale_store_warehouse_total > 0 ? '有货' : '无货'}}
            </template>
          </el-table-column>

          <el-table-column prop="price" label="商品价格" min-width="100">
            <template slot-scope="scope">
              {{scope.row.price}}元
            </template>
          </el-table-column>
          <el-table-column prop="approve_status" label="平台状态" min-width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.approve_status === 'onsale'" type="success">前台可销售</el-tag>
              <el-tag v-else-if="scope.row.approve_status === 'only_show' " type="warning">前台仅展示</el-tag>
              <el-tag v-else class="grid-content" type="danger"> 不可销售 </el-tag>
<!--              <div v-if="scope.row.approve_status === 'onsale'" class="grid-content"> 前台可销售 </div>-->
<!--              <div v-else-if="scope.row.approve_status === 'offline_sale'" class="grid-content"> 可线下销售 </div>-->
<!--              <div v-else class="grid-content"> 不可销售 </div>-->
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="params.page"
            :page-sizes="[10,20,50]"
            :total="total_count"
            :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <sideBar :visible.sync="show_sideBar" title="编辑店铺sku配置" width="60">
      <slot>
        <h3 class="content-padded">{{itemSkuParam.name}}</h3>
        <el-table :data="itemSkuList" :height="wheight-180" v-loading="loading">
          <el-table-column prop="item_id" label="规格ID" width="55"></el-table-column>
          <el-table-column prop="item_spec_desc" label="规格名称"></el-table-column>
<!--          <el-table-column prop="sales"  label="销量"></el-table-column>-->
          <el-table-column prop="item_bn" label="商品编号"></el-table-column>
          <el-table-column prop="freeze" label="冻结门店库存"></el-table-column>
          <el-table-column prop="store"  label="门店库存" width="140">
            <template slot-scope="scope" >
              <el-input v-model="scope.row.store" @change="editItemsStore(scope.$index, scope.row)"><i slot="suffix" class="el-input__icon el-icon-edit"></i></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="freeze_store_warehouse" label="冻结仓库库存"></el-table-column>
          <el-table-column prop="store_warehouse" label="仓库库存">
            <template slot-scope="scope">
              {{scope.row.store_warehouse > 0 ? '有货' : '无货'}}
            </template>
<!--            <el-input v-model="scope.row.store_warehouse" @change="editItemsStore(scope.$index, scope.row)"><i slot="suffix" class="el-input__icon el-icon-edit"></i></el-input>-->
          </el-table-column>
          <el-table-column prop="price" label="商品价格" width="140">
            <template slot-scope="scope">
              <el-input v-if="login_type === 'dealer'" v-model="scope.row.price" @change="editItemsPrice(scope.$index, scope.row)"><i slot="prefix" class="el-input__icon el-icon-edit"></i><i slot="suffix" class="el-input__icon">元</i></el-input> <!-- v-if="login_type == 'dealer' || login_type == 'admin'"-->
              <span v-else>¥ {{scope.row.price}} 元</span>
            </template>
          </el-table-column>
          <el-table-column prop="approve_status" label="商品状态" width="110">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.approve_status === 'onsale'" effect="dark" type="success">前台可销售</el-tag>
              <el-tag v-else-if="scope.row.approve_status === 'only_show' " effect="dark" type="warning">前台仅展示</el-tag>
              <el-tag v-else class="grid-content" effect="dark" type="danger">不可销售</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="上下架操作" width="160"> <!--v-if="login_type == 'dealer' || login_type == 'admin'"-->
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_can_sale"
                active-text="上架"  inactive-text="下架"
                @change="switchStatusChange(scope.$index, scope.row, false)"
                active-color="#67C23A"
                inactive-color="#E6A23C"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </slot>
    </sideBar>
    <DealerSelect :store-visible="DealerVisible" :is-valid="false" :rel-data-ids="relDealers" :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogActionDealer" :maxSelect="1"></DealerSelect>

    <DistributorSelect :store-visible="DistributorVisible" :is-valid="isValid" :distributor-valid="'true,false,delete'" :rel-data-ids="relDistributors" :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="closeDialogAction"></DistributorSelect>
  </div>
</template>
<script>
import axios from "axios";
import JSZip from "jszip";
import FileSaver from "file-saver";
import store from "../../../store";
import { mapGetters } from "vuex";
import DistributorSelect from "@/components/function/distributorSelect";
import shopSelect from "@/components/shopSelect";
import DealerSelect from "@/components/function/dealerSelect";

import { handleUploadFile } from "../../../api/common";

import {
  getDistributorItems,
  exportDistributorItems,
  delDistributorItems,
  updateDistributorItem,
  getDistributorInfo,
  getDistributorList
} from "../../../api/marketing";

import sideBar from "@/components/element/sideBar";

import ImportDialog from "@/components/importDialog";

const getWxaCodeImg = url => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
      headers: { Authorization: "bearer " + store.getters.token }
    })
      .then(data => {
        resolve(data.data);
      })
      .catch(error => {
        reject(error.toString());
      });
  });
};
export default {
  components: {
    shopSelect,
    DealerSelect,
    DistributorSelect,
    ImportDialog,
    sideBar
  },
  props: {
    distributorId: {
      type: String,
      default: ""
    },
    isLoad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValid: true,
      login_type: "default",
      show_sideBar: false,
      activeName: "first",
      total_count: 0,
      loading: false,
      DealerVisible: false,
      DealerStatus: false,
      relDealers: [],

      DistributorStatus: false,
      relDistributors: [],
      DistributorVisible: false,

      params: {
        page: 1,
        pageSize: 20,
        distributor_id: "",
        distributor_ids: [],
        keywords: "",
        keywords_bn: "",
        shop_code: "",
        is_can_sale: "_all",
        auto_receiving: ''
      },
      list: [],
      itemsChecked: [],
      tabList: [
        { name: "全部商品", value: null, activeName: "first" },
        { name: "已上架", value: "true", activeName: "second" },
        { name: "未上架", value: "true", activeName: "third" }
      ],
      editStore: false,
      itemSkuList: [],
      itemSkuParam: {
        is_sku: "true"
      }
    };
  },
  computed: {
    ...mapGetters(["wheight"])
  },
  methods: {
    //变更店铺商品库存
    editItemsStore(index, row) {
      // if (row.is_total_store === false && (row.store=='' || row.store <= 0) ) {
      //   this.$message({
      //     type: 'error',
      //     message: '非总部发货时，库存不能小于等于0!'
      //   })
      //   return;
      // }
      let form = {
        distributor_id: row.distributor_id,
        item_id: row.item_id,
        is_total_store: false,
        store: Number(row.store),
        // store_warehouse: Number(row.store_warehouse)
      };
      this.updateDistributorItem(form);
    },
    //变更店铺商品价格
    editItemsPrice(index, row) {
      let form = {
        distributor_id: row.distributor_id,
        item_id: row.item_id,
        price: row.price
      };
      this.updateDistributorItem(form);
    },
    storeSearch(val) {
      val && val.shop_id;
      this.params.distributor_id = val.shop_id;
      this.params.page = 1;
      this.getList();
    },
    handleBatchOnline(goods_can_sale) {
      if (this.itemsChecked.length <= 0) {
        this.$message({ type: "error", message: "请至少选择一个商品~" });
        return;
      }

      var goodsIds = [];
      // console.log(item)
      this.itemsChecked.forEach(item => {
        goodsIds.push(item.goods_id);
      });
      console.log(goodsIds);

      var form = {
        distributor_id: this.params.distributor_id,
        goods_id: JSON.stringify(goodsIds),
        is_can_sale: goods_can_sale
      };
      this.updateDistributorItem(form);
    },
    handleSkuEdit(row) {
      if (row.is_total_store === false) {
        this.editStore = true;
      } else {
        this.editStore = false;
      }
      this.show_sideBar = true;
      // this.itemSkuParam.item_id = row.item_id
      this.itemSkuParam.goods_id = row.goods_id;
      this.itemSkuParam.distributor_id = this.params.distributor_id;
      this.itemSkuParam.name = row.item_name;
      getDistributorItems(this.itemSkuParam).then(res => {
        this.itemSkuList = res.data.data.list;
        for (var i = 0; i < this.itemSkuList.length; i++) {
          this.itemSkuList[i].price = this.itemSkuList[i].price / 100;
          // this.itemSkuList[i].is_total_store = row.is_total_store
        }
      });
    },
    handleClick(tab, event) {
      if (tab.name == "second") {
        this.params.is_can_sale = true;
      } else if (tab.name == "third") {
        this.params.is_can_sale = false;
      } else {
        this.params.is_can_sale = "_all";
      }
      this.params.page = 1;
      this.getList();
    },
    uploadHandleChange(file, fileList) {
      let params = {
        isUploadFile: true,
        file_type: "inventory",
        file: file.raw
      };
      handleUploadFile(params).then(response => {
        this.$message({
          type: "success",
          message: "上传成功，等待处理"
        });
      });
    },

    uploadHandleChange(file, fileList) {
      let params = {
        isUploadFile: true,
        file_type: "inventory",
        file: file.raw
      };
      handleUploadFile(params).then(response => {
        this.$message({
          type: "success",
          message: "上传成功，等待处理"
        });
      });
    },
    handleSizeChange(pageSize) {
      this.params.page = 1;
      this.params.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num;
      this.getList();
    },

    openDialog() {
      this.DealerVisible = true;
    },

    dealerhandleClose(index) {
      this.relDealers.splice(index, 1);
      // this.form.distributor_ids.splice(index, 1);
      //this.$forceUpdate()
    },

    DealerChooseAction(data) {
      console.log(541, data);
      this.DealerVisible = false;
      this.DealerStatus = false;
      // if (data === null || data.length <= 0) return
      // this.relDealers = data;
      data.forEach(item => {
        this.relDealers.push(item);
        // goods_ids.push(item.goods_id)
      })

    },
    closeDialogActionDealer() {
      this.DealerStatus = false;
      this.DealerVisible = false;
    },

    //经销商导出多店铺商品 start
    DistributoreHandleClose(index) {
      this.DistributorStatus = false;
      this.DistributorVisible = false;
      this.relDistributors.splice(index, 1);
    },
    addDistributoreAction() {
      this.DistributorVisible = true;
      this.DistributorStatus = true;
    },
    getDistributor(ids) {
      let param = { distributor_id: ids };
      getDistributorList(param).then(res => {
        this.relDistributors = res.data.data.list;
      });
    },

    DistributorChooseAction(data) {
      this.DistributorVisible = false;
      this.DistributorStatus = false;
      if (data === null || data.length <= 0) return;
      this.relDistributors = data;
    },
    closeDialogAction() {
      this.DistributorStatus = false;
      this.DistributorVisible = false;
    },
    //经销商导出多店铺商品 end

    // 导出数据
    exportListDealer() {
      let post_date = this.params;
      let goods_ids = [];
      let dealer_ids = [];
      post_date.distributor_ids = [];
      post_date.export_type = "dealer_id";

      // this.itemsChecked.forEach(item => {
      //   goods_ids.push(item.goods_id)
      // })
      post_date.goods_ids = goods_ids;

      this.relDealers.forEach(item => {
        dealer_ids.push({ dealer_id: item.dealer_id });
      });
      post_date.dealer_id = dealer_ids;
      // post_date.dealer_id =
      //this.relDealers[0] != undefined ? this.relDealers[0].dealer_id : 0
      exportDistributorItems(post_date).then(response => {
        console.log("导出", response);
        if (response.data.data.status) {
          this.$message({
            type: "success",
            message: "已加入执行队列，请在设置-导出列表中下载"
          });
          return;
        }
      });
    },
    // 导出数据
    exportListStore() {
      let post_date = this.params;
      let goods_ids = [];
      post_date.export_type = "distributor_ids";

      post_date.goods_ids = goods_ids;
      console.log(this.relDistributors);

      post_date.distributor_ids = [];
      this.relDistributors.forEach(item => {
        post_date.distributor_ids.push(item.distributor_id);
      });

      console.log(post_date);
      exportDistributorItems(post_date).then(response => {
        console.log("导出", response);
        if (response.data.data.status) {
          this.$message({
            type: "success",
            message: "已加入执行队列，请在设置-导出列表中下载"
          });
          return;
        }
      });
    },

    // 导出数据
    exportList() {
      let post_date = this.params;
      let goods_ids = [];
      post_date.export_type = "distributor_id";

      this.itemsChecked.forEach(item => {
        goods_ids.push(item.goods_id);
      });
      post_date.goods_ids = goods_ids;
      exportDistributorItems(post_date).then(response => {
        console.log("导出", response);
        if (response.data.data.status) {
          this.$message({
            type: "success",
            message: "已加入执行队列，请在设置-导出列表中下载"
          });
          return;
        }
      });
    },

    getList() {
      this.loading = true;
      getDistributorItems(this.params)
        .then(response => {
          if (response.data.data.list) {
            this.list = response.data.data.list;
            for (var i = 0; i < this.list.length; i++) {
              this.list[i].price = this.list[i].price / 100;
            }
            this.total_count = parseInt(response.data.data.total_count);
          } else {
            this.list = [];
            this.total_count = 0;
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },

    handleItemsSelectionChange(val) {
      // 商品选择
      this.itemsChecked = val;
    },

    handleBatchDownload: function() {
      // handleBatchDownload (val){
      if (this.itemsChecked.length <= 0) {
        this.$message({
          type: "error",
          message: "请至少选择一个商品!"
        });
        return;
      }
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      var that = this;
      console.log(this.item);
      that.itemsChecked.forEach(item => {
        //goods_id
        // const url = this.BASE_API + '/goods/distributionGoodsWxaCodeStream?item_id=' + item.itemId + '&distributor_id=' + this.params.distributor_id
        const url =
          this.VUE_APP_BASE_API +
          "/goods/distributionGoodsWxaCodeStream?item_id=" +
          item.goods_id +
          "&distributor_id=" +
          this.params.distributor_id;
        console.log("url----1-----:");
        console.log(url);
        const promise = getWxaCodeImg(url).then(result_file => {
          // 下载文件, 并存成ArrayBuffer对象
          const file_name = item.itemName + ".png"; // 获取文件名
          zip.file(file_name, result_file, { binary: true }); // 逐个添加文件
          cache[file_name] = result_file;
        });
        promises.push(promise);
      });
      console.log(promises);
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, "店铺的商品小程序码(批量).zip"); // 利用file-saver保存文件
        });
      });
    },

    updateDistributorItem(params) {
      console.log(params);
      updateDistributorItem(params).then(res => {
        console.log(res.data.data.status);

        if (res.data.data.status == false) {
          this.getList();
          this.$message({
            type: "error",
            message: res.data.data.msg,
            duration: 2000
          });
        } else {
          this.getList();
          this.$message({
            type: "success",
            message: "修改成功",
            duration: 1000
          });
        }
      });
    },
    switchStatusChange(index, row, isDefault) {
      let form = {};
      if (isDefault) {
        form = {
          distributor_id: this.params.distributor_id,
          goods_id: row.goods_id,
          is_can_sale: row.goods_can_sale
        };
      } else {
        form = {
          distributor_id: this.params.distributor_id,
          item_id: row.item_id,
          is_can_sale: row.is_can_sale
        };
      }
      this.updateDistributorItem(form);
    },
    goodsNumberSearch() {
      this.params.page = 1;
      this.getList();
    },
    shopCodeSearch() {
      // 根据apple_id查询店铺信息
      getDistributorInfo({ shop_code: this.params.shop_code }).then(
        response => {
          if (response.data.data) {
            this.params.distributor_id = response.data.data.distributor_id;
          }
        }
      );
    },
    async getDefaultDistributor(id) {
      let params = { distributor_id: id ? id : 0 };
      let { data } = await getDistributorInfo(params);
      let distributor = data.data;
      if (distributor) {
        return distributor;
      } else {
        this.$router.go(-1);
      }
    },
    async getDistributorItemList() {
      let distributor = await this.getDefaultDistributor(
        this.params.distributor_id
      );
      if (!this.params.distributor_id && distributor) {
        this.params.distributor_id = distributor.distributor_id;
      }
    }
  },
  mounted() {
    this.login_type = this.$store.getters.login_type;
    this.$nextTick(() => {
      if (this.isLoad) {
        if (!this.distributorId) {
          this.getDistributorItemList();
        } else {
          this.params.distributor_id = this.distributorId;
          this.getDistributorItemList();
        }
      }
    });
  }
};
</script>
<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 5px;
}
.new-tag {
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
.button-new-tag {
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  height: 40px;
  line-height: 40px;
  width: 138px;
  vertical-align: bottom;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
