<template>
  <div>
    <el-row>
      <el-input class="input-b" placeholder="物料ID" v-model="params.material_id" @change="searchList">
      </el-input>
      <el-input class="input-b" placeholder="渠道ID" v-model="params.params_id" @change="searchList">
      </el-input>
      <el-button class="btn_input" @click="handleCloseDialogAction()">{{ shop_num }} <i v-if="relDistributors.length===0" class="el-icon-circle-plus icon"></i><i v-else class="el-icon-circle-close icon" @click.stop="clearDistributor()"></i></el-button>
      <el-button type="primary" icon="plus" @click="searchList">查询</el-button>
    </el-row>

    <el-table :data="materialList" style="width: 100%" :height="wheight - 200" v-loading="loading" element-loading-text="数据加载中">
      <el-table-column prop="id" width="100" label="渠道ID"></el-table-column>
      <el-table-column prop="channel_name" label="渠道名"></el-table-column>

      <el-table-column label="所属物料">
        <template slot-scope="scope">
          <div>{{ scope.row.material_id }}&nbsp;&nbsp;{{ scope.row.material_name }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="distributor_name" label="门店"></el-table-column>
      <el-table-column prop="program_path" label="小程序链接">
        <template slot-scope="scope">
          <div>
            {{ scope.row.program_path }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="链接完整参数">
        <template slot-scope="scope">
          <el-popover placement="right" trigger="click">
            <div class="pageUrl-text-overflow">
              {{ scope.row.page_url }}
            </div>
            <el-button type="text" slot="reference">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="is_sales_channel" label="是否分销渠道">
        <template slot-scope="scope">
          <span>{{ scope.row.is_sales_channel == 1 ? '是' : '否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="views_count" label="浏览数"></el-table-column>
      <el-table-column prop="status" label="物料状态">
        <template slot-scope="scope">
<!--          <span v-if="scope.row.expire_time != '0'">{{ expireTimeType(scope.row.expire_time) }}</span>-->
          <span>{{ scope.row.status == 1 ? '生效中' : '已失效' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="onCancellation(scope.row)">
            作废
          </el-button>
          <el-button type="text" @click="openPath(scope.row)">
            小程序码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="content-padded content-center">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"  :page-sizes="[10, 20, 50, 100]" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total_count">
      </el-pagination>
    </div>

    <DistributorSelect :store-visible="DistributorVisible" :rel-data-ids="relDistributors" :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="handleCloseDialogAction"></DistributorSelect>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DistributorSelect from '@/components/function/distributorSelect'
import { getDeleteTempDetailList, getMaterialDetailCancel } from '@/api/material'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
      DistributorSelect
    },
    data() {
    return {
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
        material_id: '',
        params_id: '',
        distributor_id: ''
      },
      total_count: 0,
      materialList: [],
      DistributorVisible: false,
      relDistributors: [],
      DistributorStatus: false,
      shop_num: '按门店筛选',
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    searchList() {
      this.params.page = 1
      this.deleteTempDetailList()
    },
    openPath(row) {
      if (row.qr_image) {
        window.open(row.qr_image, '_blank');
      }
    },
    onCancellation(item) {
      let params = {
        id: item.id
      }
      getMaterialDetailCancel(params).then(res => {
        this.deleteTempDetailList()
      })

    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.deleteTempDetailList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.deleteTempDetailList()
    },

    deleteTempDetailList() {
      this.loading = true
      let filter = {
        ...this.params,
        is_export: '0'
      }
      getDeleteTempDetailList(filter).then(response => {
        let arr = [...response.data.data.list]
        arr.forEach(item => {
          item.popoverVisible = false
        })
        this.materialList = arr
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
    // 选择店铺
    DistributorChooseAction(data){
        let arr = []
        data.forEach(i => {
          arr.push(i.distributor_id)
        });
        this.params.distributor_id = arr.join(',')
        this.relDistributors = data
        if (data.length > 0) {
          this.shop_num = `已选择${data.length}门店`
        } else {
          this.shop_num = '按门店筛选'
        }
        this.handleCloseDialogAction()
      },
      handleCloseDialogAction(){
        this.DistributorStatus = !this.DistributorStatus
        this.DistributorVisible = !this.DistributorVisible
      },
      clearDistributor(){
        this.params.distributor_id = ''
        this.relDistributors = []
        this.shop_num = '按门店筛选'
    },
    expireTimeType(time) {
      let newTime = new Date().getTime() / 1000
      if (newTime > Number(time)) {
        return '已失效'
      } else {
        return '生效中'
      }
    }
  },
  mounted() {
    let { id } = this.$router.currentRoute.query
    if (id) {
      this.params.material_id = id
    }
    this.deleteTempDetailList()
  }
}
</script>
<style scoped leng="scss">
.popover-btns {
  margin-top: 10px;
}
.text-overflow{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pageUrl-text-overflow{
  width: 200px;
}
</style>
